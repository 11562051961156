<template>
  <div>
    <ion-label position="stacked">Synlig hvis</ion-label>
    <div
      v-for="(visibleRule, ruleIndex) in visibleRules"
      :key="visibleRule.ruleID"
    >
      <ion-item style="overflow: visible">
        <DeleteButtonInline
          @click="deleteCurrentRule($event, ruleIndex)"
          slot="start"
        ></DeleteButtonInline>

        <!-- Edit rule -->
        <div v-if="visibleRule.ruleID" style="display: flex">
          <ion-input
            @ionInput="updateRuleProperty($event, visibleRule.ruleID)"
            name="name"
            mode="ios"
            :value="ruleList[visibleRule.ruleID]?.name"
            placeholder="Evt. navn"
          ></ion-input>
          <RuleChainOperator
            v-if="ruleIndex != 0"
            :visibleRuleIndex="ruleIndex"
            :visibleRulesArrayPath="visibleRulesArrayPath"
          ></RuleChainOperator>
          <ExpandButton
            @click="toggleExpand(ruleIndex)"
            :isExpanded="isExpanded[ruleIndex]"
          ></ExpandButton>
        </div>

        <!-- Select rule -->
        <div v-if="!visibleRule.ruleID" style="flex: 1 1 0">
          <ion-label>
            <ion-button
              :id="'rule-select-popover-id-' + ruleIndex"
              color="medium"
              expand="full"
              size="small"
              class="rule-select-button"
              >Regel</ion-button
            ></ion-label
          >
          <ion-popover
            :trigger="'rule-select-popover-id-' + ruleIndex"
            :dismiss-on-select="true"
            style="--max-height: 300px"
          >
            <ion-content>
              <ion-item
                button
                @click="newRuleSelected($event, 'newRule', ruleIndex)"
                >Ny regel...</ion-item
              >
              <ion-item
                button
                @click="newRuleSelected($event, 'newRuleFromValues', ruleIndex)"
                >Valgte værdier...</ion-item
              >
              <ion-accordion-group :value="accordionState[ruleIndex]">
                <ion-accordion value="selectRule">
                  <ion-item
                    slot="header"
                    @click.stop="accordionState[ruleIndex] = 'selectRule'"
                  >
                    <ion-label>Vælg regel</ion-label>
                  </ion-item>
                  <ion-list slot="content">
                    <ion-item
                      v-for="appRule in appRules"
                      :key="appRule.ruleID"
                      button
                      @click="
                        newRuleSelected(
                          $event,
                          'selectRule',
                          ruleIndex,
                          appRule.ruleID
                        )
                      "
                      >{{ appRule.name }}</ion-item
                    >
                  </ion-list>
                </ion-accordion>
                <ion-accordion value="copyRule">
                  <ion-item
                    slot="header"
                    @click.stop="accordionState[ruleIndex] = 'copyRule'"
                  >
                    <ion-label>Kopier regel</ion-label>
                  </ion-item>
                  <ion-list slot="content">
                    <ion-item
                      v-for="appRule in appRules"
                      :key="appRule.ruleID"
                      button
                      @click="
                        newRuleSelected(
                          $event,
                          'copyRule',
                          ruleIndex,
                          appRule.ruleID
                        )
                      "
                      >{{ appRule.name }}</ion-item
                    >
                  </ion-list>
                </ion-accordion>
              </ion-accordion-group>
            </ion-content>
          </ion-popover>
        </div>
      </ion-item>

      <!-- SubRules -->
      <div v-if="isExpanded[ruleIndex]">
        <SubRules :visibleRule="visibleRule"></SubRules>
      </div>
    </div>

    <!-- Add new rule -->
    <div>
      <ion-item mode="ios" lines="none">
        <AddButtonInline slot="start" @click="addRule"></AddButtonInline>
      </ion-item>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { join, chain } from "lodash";

import {
  IonItem,
  IonButton,
  IonLabel,
  IonInput,
  IonAccordion,
  IonAccordionGroup,
  IonPopover,
  IonList,
  IonContent,
} from "@ionic/vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";
import ExpandButton from "@/views/components/buttons/ExpandButton.vue";
import AddButtonInline from "@/views/components/buttons/AddButtonInline.vue";
import RuleChainOperator from "@/views/components/RuleChainOperator.vue";
import SubRules from "@/views/components/sub-rules/SubRules.vue";

export default {
  name: "IsVisible",
  props: {
    visibleRulesArrayPath: {
      type: Array,
    },
  },
  components: {
    IonItem,
    DeleteButtonInline,
    ExpandButton,
    AddButtonInline,
    IonButton,
    IonLabel,
    RuleChainOperator,
    SubRules,
    IonInput,
    IonAccordion,
    IonAccordionGroup,
    IonPopover,
    IonList,
    IonContent,
  },
  setup(props) {
    const { ruleList, appID, getAppProperty } = useStoreGetters();
    const {
      newRule,
      newRuleFromValues,
      selectRule,
      copyRule,
      deleteRule,
      updateAppProperty,
    } = useStoreSetters();
    const accordionState = ref([]);
    const popoverIsOpen = ref([]);

    const visibleRules = computed(() =>
      getAppProperty.value(props.visibleRulesArrayPath)
    );

    const appRules = computed(() =>
      chain(ruleList.value)
        .map((rule, key) => {
          rule["ruleID"] = key;
          return rule;
        })
        .filter((rule) => rule.appID == appID.value && rule.name != "")
        .sortBy(["name"])
        .value()
    );

    const updateRuleProperty = (event, ruleID) => {
      const property = event.target.name;
      const value = event.target.value;
      const updateObject = {};
      updateObject[`ruleList/${ruleID}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    const addRule = () => {
      const newVisibleRules = [
        ...(visibleRules.value ?? []),
        { chainOperator: "&&", ruleID: "" },
      ];
      const updateObject = {};
      updateObject[join(props.visibleRulesArrayPath, "/")] = newVisibleRules;
      updateAppProperty(updateObject, appID.value);
    };

    const deleteCurrentRule = (event, ruleIndex) => {
      deleteRule(props.visibleRulesArrayPath, ruleIndex, appID.value);
    };

    const newRuleSelected = async (event, buttonName, ruleIndex, ruleID) => {
      switch (buttonName) {
        case "newRule":
          newRule(appID.value, props.visibleRulesArrayPath, ruleIndex);
          break;
        case "newRuleFromValues":
          newRuleFromValues(
            appID.value,
            props.visibleRulesArrayPath,
            ruleIndex
          );
          break;
        case "selectRule":
          selectRule(appID.value, props.visibleRulesArrayPath, ruleIndex, ruleID);
          break;
        case "copyRule":
          copyRule(appID.value, props.visibleRulesArrayPath, ruleIndex, ruleID);
          break;
        default:
          break;
      }
    };

    const isExpanded = reactive([]);
    watch(
      () => props.visibleRulesArrayPath[1],
      () => {
        /* close when itemID or tabID is changed */
        isExpanded.value = isExpanded.fill(false);
      }
    );
    const toggleExpand = (index) => {
      isExpanded[index] = isExpanded[index] ? false : true;
    };

    return {
      ruleList,
      appRules,
      visibleRules,
      updateRuleProperty,
      addRule,
      deleteCurrentRule,
      popoverIsOpen,
      accordionState,
      newRuleSelected,
      toggleExpand,
      isExpanded,
      newRulePopoverOptions: { cssClass: "new-rule-popover" },
    };
  },
};
</script>

<style>
.new-rule-popover .default-option {
  --color: var(--ion-color-medium);
}
</style>