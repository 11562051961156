<template>
  <ion-content>
    <EditButton currentEditComponent="AppListEdit" slot="fixed"></EditButton>
    <component
      :is="
        ['AppListEdit', 'AppInfoEdit'].includes(currentEdit.component)
          ? AppListEditView
          : AppListView
      "
    ></component>
  </ion-content>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { IonContent } from "@ionic/vue";
import AppListView from "@/views/apps/left-sidebar/AppListView.vue";
import AppListEditView from "@/views/apps/left-sidebar/AppListEditView.vue";
import EditButton from "@/views/components/buttons/EditButton.vue";

export default {
  name: "AppLeftSidebarView",
  components: {
    IonContent,
    EditButton,
  },
  setup() {
    const { currentEdit } = useStoreGetters();

    return {
      currentEdit,
      AppListView,
      AppListEditView,
    };
  },
};
</script>

<style scoped>
</style>