<template>
  <ion-list
    style="display: inline-block; vertical-align: top; padding-right: 16px"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <ion-radio-group
      :value="appItemValues?.[itemID]"
      @ionChange="radioGroupChanged($event)"
      allowEmptySelection="true"
    >
      <ion-list-header>
        <ion-label>
          <h2 :innerHTML="itemList[itemID]?.titel"></h2>
          <p :innerHTML="itemList[itemID]?.subTitel"></p>
        </ion-label>
      </ion-list-header>
      <ion-item v-for="subItemID in itemList[itemID].subItems" :key="subItemID">
        <ion-label class="ion-text-wrap">{{
          subItemList[subItemID]?.name
        }}</ion-label>
        <ion-radio slot="start" color="primary" :value="subItemID"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonLabel,
  IonRadioGroup,
  IonListHeader,
  IonList,
  IonItem,
  IonRadio,
} from "@ionic/vue";

export default {
  name: "RadioSelectList",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    IonLabel,
    IonRadioGroup,
    IonListHeader,
    IonItem,
    IonRadio,
    IonList,
  },
  setup(props) {
    const { itemValues, itemList, subItemList } = useStoreGetters();
    const { setItemValue } = useStoreSetters();

    const appItemValues = computed(() => itemValues.value[props.appID]);

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const radioGroupChanged = (event) => {
      setItemValue(props.appID, props.itemID, event.target.value);
    };

    return {
      subItems,
      subItemList,
      itemList,
      appItemValues,
      radioGroupChanged,
    };
  },
};
</script>

<style>
</style>