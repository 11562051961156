<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <div
      v-for="(subItemID) in subItems"
      :key="subItemID"
    >
      <div v-if="evaluate(subItemList[subItemID]?.visibleRules)">
        <!-- Chart -->
        <component
        v-if="!error"
          :is="subItemList[subItemID]?.type"
          style="padding: 0 16px; margin: auto"
          :style="{
            height: subItemList[subItemID]?.height + 'px',
            width: subItemList[subItemID]?.width + '%',
          }"
          :subItemID="subItemID"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onErrorCaptured, watch } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
import BarChart from "@/views/components/charts/BarChart";
import LineChart from "@/views/components/charts/LineChart.vue";
import ScatterChart from "@/views/components/charts/ScatterChart.vue";
import LineScatterChart from "@/views/components/charts/LineScatterChart.vue";
export default {
  name: "ChartOutput",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    BarChart,
    LineChart,
    ScatterChart,
    LineScatterChart,
  },
  setup(props) {
    const { itemList, subItemList } = useStoreGetters();
    const { evaluate } = useEvaluate();
    const error = ref(false);

    const subItems = computed(() => itemList.value[props.itemID].subItems);
    
    onErrorCaptured((err, target, info) => {
      error.value = true;
      console.log("Error:", err.message,"Target:", target, "Info:", info)
      return false;
    });

    // Reset error when changing an item property
    watch(subItems, () => {
      if(error.value) error.value = false;
    }, {flush: 'post'});

    return {
      itemList,
      subItems,
      subItemList,
      evaluate,
      error,
    };
  },
};
</script>

<style>
</style>