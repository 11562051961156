<template>
  <ion-accordion-group :value="itemHasName ? 'module-options' : null">
    <ion-accordion value="module-options">
      <ion-item slot="header" lines="full">
        <ion-label class="ion-text-nowrap"><h1>Diagram Output</h1></ion-label>
      </ion-item>

      <ion-list slot="content" mode="ios" style="padding-left: 16px; padding-right: 16px">
        <!-- Select subItem to edit -->
        <ion-item v-if="itemList[itemID]?.subItems">
          <ion-label position="stacked">Output</ion-label>
          <ion-select
            @ionChange="updateCurrentEdit($event)"
            :interface-options="typePopoverOptions"
            interface="popover"
            placeholder="Vælg"
            name="subItemID"
            :value="subItemID"
            :key="subItemList[subItemID]?.name"
          >
            <ion-select-option v-for="subItemID in itemList[itemID]?.subItems" :key="subItemID" :value="subItemID">{{
              subItemList[subItemID]?.name
            }}</ion-select-option>
          </ion-select>
        </ion-item>

        <!-- SubItem name -->
        <ion-label v-if="subItemID != null" position="stacked">Navn</ion-label>
        <ion-item mode="ios" v-if="subItemID != null">
          <DeleteButtonInline slot="start" @click="deleteSubItem(subItemID, itemID, appID)"></DeleteButtonInline>
          <ion-input
            @ionInput="updateSubItemProperty($event.target.value, 'name')"
            mode="ios"
            clear-input
            :value="subItemList[subItemID]?.name"
          ></ion-input>
        </ion-item>

        <!-- Chart type -->
        <ion-item mode="ios" v-if="subItemID != null">
          <ion-label position="stacked">Type</ion-label>
          <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
          <ion-select
            @ionChange="updateSubItemProperty($event.target.value, 'type')"
            :interface-options="typePopoverOptions"
            interface="popover"
            placeholder="Vælg"
            :value="subItemList[subItemID]?.type"
            :key="subItemList[subItemID]?.type"
          >
            <ion-select-option value="ScatterChart"> Punktdiagram </ion-select-option>
            <ion-select-option value="LineChart"> Linjediagram </ion-select-option>
            <ion-select-option value="BarChart"> Søjlediagram </ion-select-option>
            <ion-select-option value="LineScatterChart"> Linje-/punktdiagram </ion-select-option>
          </ion-select>
        </ion-item>

        <!-- Height -->
        <ion-item v-if="subItemID != null" mode="ios">
          <ion-label position="stacked">Højde (px)</ion-label>
          <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
          <ion-input
            inputmode="numeric"
            type="number"
            mode="ios"
            min="100"
            max="500"
            :value="subItemList[subItemID]?.height"
            @ionInput="updateSubItemProperty(toNumber($event.target.value), 'height')"
          >
          </ion-input>
        </ion-item>

        <!-- Width -->
        <ion-item v-if="subItemID != null" mode="ios">
          <ion-label position="stacked">Bredde (%)</ion-label>
          <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
          <ion-input
            inputmode="numeric"
            type="number"
            mode="ios"
            :value="subItemList[subItemID]?.width"
            @ionInput="updateSubItemProperty(toNumber($event.target.value), 'width')"
          >
          </ion-input>
        </ion-item>

        <!-- SubItem visible -->
        <IsVisible
          v-if="subItemID != null"
          :visibleRulesArrayPath="['subItemList', subItemID, 'visibleRules']"
        ></IsVisible>

        <ion-button @click="addSubItem" color="primary" expand="full" fill="solid" class="ion-margin" size="small"
          >Tilføj
        </ion-button>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import IsVisible from "@/views/components/IsVisible.vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";
import PlaceholderButtonInline from "@/views/components/buttons/PlaceholderButtonInline.vue";
import { toNumber } from "@/utilities/utilityFunctions";
import {
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/vue";

export default {
  name: "ChartOutputSidebarEdit",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonButton,
    IsVisible,
    DeleteButtonInline,
    PlaceholderButtonInline,
    IonAccordionGroup,
    IonAccordion,
  },
  setup() {
    const { itemList, currentEdit, subItemList } = useStoreGetters();
    const { updateAppProperty, setUIStateProperty, newSubItem, deleteSubItem } = useStoreSetters();

    const appID = computed(() => currentEdit.value.appID);
    const tabID = computed(() => currentEdit.value.tabID);
    const itemID = computed(() => currentEdit.value.itemID);
    const subItemID = computed(() => currentEdit.value.subItemID);

    const itemHasName = itemList.value[itemID.value].name ? true : false;

    const updateCurrentEdit = (event) => {
      const property = event.target.name;
      const value = event.target.value;
      setUIStateProperty(`apps.currentEdit[${property}]`, value);
    };

    const addSubItem = () => {
      newSubItem(appID.value, tabID.value, itemID.value, { name: "" });
    };

    const updateSubItemProperty = (value, property) => {
      const updateObject = {};
      updateObject[`subItemList/${subItemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      appID,
      toNumber,
      itemHasName,
      itemID,
      itemList,
      subItemID,
      subItemList,
      updateSubItemProperty,
      updateCurrentEdit,
      addSubItem,
      deleteSubItem,
      typePopoverOptions: {},
    };
  },
};
</script>

<style>
</style>