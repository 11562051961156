<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">Modul rækkefølge</ion-label>
      </ion-list-header>

      <ion-reorder-group
        :disabled="false"
        @ionItemReorder="reorderList($event)"
      >
        <ion-item mode="ios" v-for="itemID in tabItems" :key="itemID">
          {{ itemList[itemID]?.name }}
          <ion-reorder mode="md" slot="end"></ion-reorder>
        </ion-item>
      </ion-reorder-group>
    </ion-list>
  </ion-card>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonCard,
  IonReorder,
  IonReorderGroup,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";

export default {
  name: "ItemListEdit",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonCard,
    IonReorder,
    IonReorderGroup,
    CloseButton,
  },
  setup() {
    const { appID, tabList, itemList, currentEdit } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const tabID = computed(() => currentEdit.value.tabID);
    const tabItems = computed(() => tabList.value[tabID.value].tabItems);

    const reorderList = (event) => {
      const updateObject = {};
      updateObject[`tabList/${tabID.value}/tabItems`] = event.detail.complete(
        tabItems.value
      );
      updateAppProperty(updateObject, appID.value);
    };

    return {
      tabItems,
      itemList,
      reorderList,
    };
  },
};
</script>

<style>
</style>