<template>
  <div>
    <div
      class="box-shadow-margin"
      :class="{ 'box-shadow': currentEdit.component == 'AppListEdit' }"
    >
      <ion-accordion-group :value="accordionValue" @ionChange="setAccordionValue($event.target.value)">
        <ion-accordion
          v-for="(container, containerIndex) in appList"
          :key="container.apps"
          :value="container.containerID"
        >
          <ion-item slot="header">
            <ion-label>{{ container.title }}</ion-label>
            <AddButtonInline
              v-show="accordionValue == container.containerID"
              @click.stop="newAppFunction(containerIndex)"
              slot="end"
            ></AddButtonInline>
          </ion-item>

          <ion-list slot="content">
            <ion-item
              v-for="appID in container.apps"
              :key="appID"
              @click="setCurrentEditFunction(appID)"
              button
              slot="content"
              class="box-shadow-margin"
              :class="{
                'box-shadow': currentEdit.appID == appID,
                'box-shadow-hover':
                  (currentEdit.component == 'AppInfoEdit' ||
                    currentEdit.component == 'AppListEdit') &&
                  currentEdit.appID != appID,
              }"
            >
              <ion-label class="ion-text-wrap">
                <h2>{{ appInfo[appID].name }}</h2>
                <p>{{ appInfo[appID].description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import AddButtonInline from "@/views/components/buttons/AddButtonInline.vue";

import {
  IonItem,
  IonList,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default {
  name: "AppListEditView",
  components: {
    IonItem,
    IonList,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    AddButtonInline,
  },
  setup() {
    const { appList, appInfo, currentEdit, accordionValue } = useStoreGetters();
    const { setCurrentEdit, newApp, setAccordionValue, setCurrentTabID } = useStoreSetters();
    const router = useRouter();

    const setCurrentEditFunction = async (appID) => {
      setCurrentTabID(null);
      await router.push({
        name: "AppSinglePage",
        params: { appSlug: appInfo.value[appID].slug },
      });
      setCurrentEdit({
        component: "AppInfoEdit",
        appID: appID,
      });
      setCurrentTabID(appInfo.value[appID].appTabs?.[0]);
    };

    const newAppFunction = (containerIndex) => {
      newApp(containerIndex);
    };

    return {
      appList,
      appInfo,
      newAppFunction,
      setCurrentEditFunction,
      currentEdit,
      accordionValue,
      setAccordionValue
    };
  },
};
</script>

<style scoped>
</style>