import { useStore } from "vuex";

export const useStoreSetters = () => {
  const store = useStore();

  /* AppState */
  // LocalDB
  const setItemValue = (appID, itemID, newValue) => {
    store.commit("appState/setItemValue", {
      appID: appID,
      itemID: itemID,
      newValue: newValue,
    });
  };
  const updateItemValueIDs = () => {
    store.commit("appState/updateItemValueIDs");
  };

  // Apps
  const updateAppProperty = async (updateObject, appID = null, mementoID = null) => {
    return store.dispatch("appState/updateAppProperty", {
      updateObject: updateObject,
      appID: appID,
      mementoID: mementoID,
    });
  };
  const deleteRule = async (rulesArrayPath, ruleIndex, appID = null, mementoID = null) => {
    return store.dispatch("appState/deleteRule", {
      rulesArrayPath: rulesArrayPath,
      ruleIndex: ruleIndex,
      appID: appID,
      mementoID: mementoID,
    });
  };
  const deleteSubItem = async (subItemID, itemID, appID = null, mementoID = null) => {
    return store.dispatch("appState/deleteSubItem", {
      subItemID: subItemID,
      itemID: itemID,
      appID: appID,
      mementoID: mementoID,
    });
  };
  const deleteItem = async (itemID, tabID, appID = null, mementoID = null) => {
    return store.dispatch("appState/deleteItem", {
      itemID: itemID,
      tabID: tabID,
      appID: appID,
      mementoID: mementoID,
    });
  };
  const deleteTab = async (tabID, appID, mementoID = null) => {
    return store.dispatch("appState/deleteTab", {
      tabID: tabID,
      appID: appID,
      mementoID: mementoID,
    });
  };
  const deleteApp = async (appID, mementoID = null) => {
    return store.dispatch("appState/deleteApp", {
      appID: appID,
      mementoID: mementoID,
    });
  };
  const newApp = async (containerIndex) => {
    store.dispatch("appState/newApp", { containerIndex: containerIndex });
  };
  const newTab = async (appID) => {
    store.dispatch("appState/newTab", { appID: appID });
  };
  const newItem = async (appID, tabID) => {
    store.dispatch("appState/newItem", { appID: appID, tabID: tabID });
  };
  const newSubItem = async (appID, tabID, itemID, subItemInfoTemplate) => {
    store.dispatch("appState/newSubItem", {
      appID: appID,
      tabID: tabID,
      itemID: itemID,
      subItemInfoTemplate: subItemInfoTemplate,
    });
  };
  const newRule = async (appID, visibleRulesArrayPath, ruleIndex) => {
    await store.dispatch("appState/newRule", {
      appID: appID,
      visibleRulesArrayPath: visibleRulesArrayPath,
      ruleIndex: ruleIndex,
    });
  };
  const newRuleFromValues = async (appID, visibleRulesArrayPath, ruleIndex) => {
    await store.dispatch("appState/newRuleFromValues", {
      appID: appID,
      visibleRulesArrayPath: visibleRulesArrayPath,
      ruleIndex: ruleIndex,
    });
  };
  const selectRule = async (appID, visibleRulesArrayPath, ruleIndex, ruleID) => {
    await store.dispatch("appState/selectRule", {
      appID: appID,
      visibleRulesArrayPath: visibleRulesArrayPath,
      ruleIndex: ruleIndex,
      ruleID: ruleID,
    });
  };
  const copyRule = async (appID, visibleRulesArrayPath, ruleIndex, ruleID) => {
    await store.dispatch("appState/copyRule", {
      appID: appID,
      visibleRulesArrayPath: visibleRulesArrayPath,
      ruleIndex: ruleIndex,
      ruleID: ruleID,
    });
  };
  const publishStage = () => {
    return store.dispatch("appState/publishStage");
  };
  const uploadImage = (blobInfo, appID, subItemID) => {
    return store.dispatch("appState/uploadImage", {
      blobInfo: blobInfo,
      appID: appID,
      subItemID: subItemID,
    });
  };

  /* mementos */
  const undoChange = async (appID) => {
    return store.dispatch("appState/undoChange", {
      appID: appID,
    });
  };

  const redoChange = async (appID) => {
    return store.dispatch("appState/redoChange", {
      appID: appID,
    });
  };

  /* UIState */
  const setCurrentTabID = (tabID) => {
    store.commit("setCurrentTabID", tabID);
  };
  const setCurrentEdit = (currentEdit) => {
    store.commit("setCurrentEdit", currentEdit);
  };
  const setUIStateProperty = (path, value) => {
    store.commit("setUIStateProperty", { path: path, value: value });
  };
  const setEditMode = async (editMode) => {
    store.dispatch("setEditMode", { editMode: editMode });
  };
  const setAccordionValue = (accordionValue) => {
    store.commit("setAccordionValue", accordionValue);
  };

  return {
    setItemValue,
    updateItemValueIDs,
    updateAppProperty,
    deleteRule,
    deleteSubItem,
    deleteItem,
    deleteTab,
    deleteApp,
    newApp,
    newTab,
    newItem,
    newSubItem,
    newRule,
    newRuleFromValues,
    selectRule,
    copyRule,
    publishStage,
    uploadImage,
    undoChange,
    redoChange,
    setCurrentTabID,
    setCurrentEdit,
    setUIStateProperty,
    setEditMode,
    setAccordionValue,
  };
};
