<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <div
      v-for="(subItemID) in subItems"
      :key="subItemID"
    >
      <div
        v-if="evaluate(subItemList[subItemID]?.visibleRules)"
        style="
          padding: 0 12px;
          margin: 0 16px;
          border-style: solid;
          border-width: 4px;
          border-radius: 8px;
          font-size: 1.2em;
        "
        :style="{
          color: subItemList[subItemID].textColor ?? '#000000',
          'background-color':
            subItemList[subItemID].backgroundColor ?? '#FFFFFF',
          'border-color': subItemList[subItemID].borderColor ?? '#000000',
        }"
        v-html="evaluateExpressions(parseHTML(subItemList[subItemID]?.output))"
      ></div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
import { useExpression } from "@/utilities/composables/useExpression";
export default {
  name: "InfoBoxOutput",
  props: {
    appID: String,
    itemID: String,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { evaluate } = useEvaluate();
    const { parseHTML, evaluateExpressions } = useExpression();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    return {
      itemList,
      subItems,
      subItemList,
      editMode,
      currentEdit,
      evaluate,
      parseHTML,
      evaluateExpressions,
    };
  },
};
</script>

<style>
</style>