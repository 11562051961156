<template>
  <ion-list
    style="display: inline-block; vertical-align: top; padding-right: 16px"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <ion-list-header>
      <ion-label>
        <h2 :innerHTML="itemList[itemID]?.titel"></h2>
        <p :innerHTML="itemList[itemID]?.subTitel"></p>
      </ion-label>
    </ion-list-header>
    <!-- Group checkbox -->
    <ion-item v-if="itemList[itemID].showGroupCheckbox">
      <ion-label class="ion-text-wrap">{{
        checkboxGroupArray.length == subItems.length && subItems.length != 0
          ? "Fravælg alle"
          : "Vælg alle"
      }}</ion-label>
      <ion-checkbox
        id="Group"
        slot="start"
        color="dark"
        @ionChange="checkboxGroupChanged($event.target.checked)"
        :checked="
          checkboxGroupArray.length == subItems.length && subItems.length != 0
        "
      ></ion-checkbox>
    </ion-item>

    <!-- Single checkboxes -->
    <ion-item v-for="subItemID in subItems" :key="subItemID">
      <ion-label class="ion-text-wrap">{{
        subItemList[subItemID]?.name
      }}</ion-label>
      <ion-checkbox
        :id="subItemID"
        slot="start"
        color="primary"
        @ionChange="checkboxChanged($event.target.checked, subItemID)"
        :checked="checkboxGroupArray.includes(subItemID)"
      ></ion-checkbox>
    </ion-item>
  </ion-list>
</template>

<script>
import { computed, ref } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonLabel,
  IonListHeader,
  IonList,
  IonItem,
  IonCheckbox,
} from "@ionic/vue";

export default {
  name: "CheckboxSelectList",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    IonLabel,
    IonListHeader,
    IonItem,
    IonList,
    IonCheckbox,
  },
  setup(props) {
    const { itemValues, itemList, subItemList } = useStoreGetters();
    const { setItemValue } = useStoreSetters();

    const appItemValues = computed(() => itemValues.value[props.appID]);

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const checkboxGroupArray = ref([]);

    const checkboxGroupChanged = (checked) => {
      if (!checked && checkboxGroupArray.value.length != subItems.value.length)
        return;
      if (checked) checkboxGroupArray.value = subItems.value;
      else checkboxGroupArray.value = [];
      setItemValue(props.appID, props.itemID, checkboxGroupArray.value);
    };

    const checkboxChanged = (checked, subItemID) => {
      if (checkboxGroupArray.value.length == subItems.value.length && checked)
        return;
      if (checkboxGroupArray.value.length == 0 && !checked) return;
      if (checked && !checkboxGroupArray.value.includes(subItemID)) {
        checkboxGroupArray.value.push(subItemID);
      } else if (!checked && checkboxGroupArray.value.includes(subItemID)) {
        checkboxGroupArray.value = checkboxGroupArray.value.filter(
          (element) => element != subItemID
        );
      } else return;
      setItemValue(props.appID, props.itemID, checkboxGroupArray.value);
    };

    return {
      subItems,
      subItemList,
      itemList,
      appItemValues,
      checkboxGroupArray,
      checkboxChanged,
      checkboxGroupChanged,
    };
  },
};
</script>

<style>
</style>