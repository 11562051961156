<template>
  <div
    style="
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-right: 14px;
    "
  >
    <ion-segment
      style="flex-grow: 1"
      class="box-shadow-margin"
      :class="{ 'box-shadow': currentEdit.component == 'TabListEdit' }"
      scrollable
      :value="currentTabID"
      mode="md"
    >
      <ion-segment-button
        v-for="tabID in appTabs"
        @click="tabChanged(tabID)"
        :key="tabID"
        :value="tabID"
        :class="{
          'box-shadow':
            currentEdit.component == 'TabInfoEdit' && currentEdit.tabID == tabID,
          'box-shadow-hover':
            (currentEdit.component == 'TabInfoEdit' ||
              currentEdit.component == 'TabListEdit') &&
            currentEdit.tabID != tabID,

          'hide-indicator': ['TabInfoEdit', 'TabListEdit'].includes(
            currentEdit.component
          ),
        }"
      >
        <ion-label>{{ tabList[tabID]?.name }}</ion-label>
      </ion-segment-button>
    </ion-segment>
    <AddButton
      v-if="currentEdit.component == 'TabListEdit'"
      @click="newTabFunction"
    ></AddButton>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import AddButton from "@/views/components/buttons/AddButton.vue";
export default {
  name: "TabListEditView",
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    AddButton,
  },
  setup() {
    const { appID, appInfo, currentEdit, tabList, currentTabID } = useStoreGetters();
    const { setCurrentTabID, setCurrentEdit, newTab } = useStoreSetters();

    const appTabs = computed(() => appInfo.value[appID.value].appTabs);
    // Deselect tabs at start
    //store.commit("setCurrentTabID", null);


    const tabChanged = (currentTabID) => {
      setCurrentTabID(currentTabID)
      setCurrentEdit({
        component: "TabInfoEdit",
        appID: appID.value,
        tabID: currentTabID,
      })
    };

    const newTabFunction = () => {
      newTab(appID.value)
    };

    return {
      appID,
      appTabs,
      tabList,
      currentTabID,
      currentEdit,
      tabChanged,
      newTabFunction,
    };
  },
};
</script>

<style scoped>
.hide-indicator {
  --indicator-height: 0;
}
ion-segment-button {
  --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.8);;
}
</style>