<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <!-- ItemListEdit -->
    <EmptyModule
      v-if="currentEdit.component == 'ItemListEdit'"
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit but other item selected -->
    <EmptyModule
      v-else-if="
        currentEdit.component == 'ItemInfoEdit' && currentEdit.itemID != itemID
      "
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit -->
    <div
      v-else-if="currentEdit.subItemID != null"
      style="
        border-style: solid;
        border-width: 4px;
        padding: 12px;
        border-radius: 8px;
        font-size: 1.2em;
      "
      class="ion-margin"
      :style="{
        color: subItemList[currentEdit.subItemID]?.textColor ?? '#000000',
        'background-color':
          subItemList[currentEdit.subItemID]?.backgroundColor ?? '#FFFFFF',
        'border-color':
          subItemList[currentEdit.subItemID]?.borderColor ?? '#000000',
      }"
    >
      <WYSIWYG
        :key="currentEdit.subItemID"
        :appID="currentEdit.appID"
        :subItemID="currentEdit.subItemID"
        :content="subItemList[currentEdit.subItemID]?.output"
        @update:content="updateSubItemProperty($event)"
        editorType="Inline"
        placeholder="Skriv tekst her..."
      ></WYSIWYG>
    </div>

    <!-- SubItem not selected -->
    <div v-else>
      <EmptyModule moduleText="Vælg/tilføj output"></EmptyModule>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
import WYSIWYG from "@/views/components/WYSIWYG.vue";

export default {
  name: "InfoBoxOutputMainEdit",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    EmptyModule,
    WYSIWYG,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const updateSubItemProperty = (content) => {
      const updateObject = {};
      updateObject[`subItemList/${currentEdit.value.subItemID}/output`] =
        content;
      updateAppProperty(updateObject, props.appID);
    };

    return {
      itemList,
      subItems,
      subItemList,
      updateSubItemProperty,
      editMode,
      currentEdit,
    };
  },
};
</script>

<style>
</style>