import { computed } from "vue";
/* import { useStore } from "vuex"; */
import store from '@/store';
/* import { useRoute } from "vue-router"; */
import router from '@/router';



export const useStoreGetters = () => {
    /* const store = useStore(); */
    /* const r = useRoute(); */

    /* AppState */
    // LocalDB
    const itemValues = computed(() => store.state.appState.localDB.itemValues);

    // Apps
    const appInfo = computed(() => store.state.appState.apps.appInfo);
    const appList = computed(() => store.state.appState.apps.appList);
    const itemList = computed(() => store.state.appState.apps.itemList);
    const subItemList = computed(() => store.state.appState.apps.subItemList);
    const tabList = computed(() => store.state.appState.apps.tabList);
    const ruleList = computed(() => store.state.appState.apps.ruleList);
    const root = computed(() => store.state.appState.apps.root);

    // Mementos
    const mementos = computed(() => store.state.appState.mementos)

    // Index
    const editMode = computed(() => store.state.UIState.apps.editMode);
    const currentEdit = computed(() => store.state.UIState.apps.currentEdit);
    const currentStoreName = computed(() => store.state.UIState.apps.currentStoreName);
    const currentTabID = computed(() => store.state.UIState.apps.currentTabID);
    const accordionValue = computed(() => store.state.UIState.apps.accordionValue);

    // Getters
    const appID = computed(() => store.getters["appState/appIDFromSlug"](router.currentRoute.value.params.appSlug));
    const getAppProperty = computed(() => store.getters["appState/getAppProperty"])
    const currentEditing = computed(() => store.getters.currentEditing);

    return {
        itemValues,
        appInfo,
        appList,
        itemList,
        subItemList,
        tabList,
        ruleList,
        root,
        mementos,
        editMode,
        currentEdit,
        currentStoreName,
        currentTabID,
        appID,
        getAppProperty,
        currentEditing,
        accordionValue,
    }
}