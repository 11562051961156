import { map, mergeWith, isArray, keys, pickBy } from "lodash";
import { flatten, unflatten } from "flat";

const isNumber = (stringInput) => {
  return !isNaN(stringInput) && !isNaN(parseFloat(stringInput));
};

const toNumber = (stringInput) => {
  if (typeof stringInput == "number") return stringInput;
  if (stringInput.trim().length == 0) return null;
  else return Number(stringInput);
};

/* UUIDv4 without hyphens */
const uuid = () => {
  return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const flattenAppList = (appList) => {
  const flatList = appList.reduce((prev, curr) => {
    return [
      ...prev,
      { title: curr.title, containerID: curr.containerID },
      ...map(curr.apps, (appID) => {
        return { appID: appID };
      }),
    ];
  }, []);
  return flatList;
};

const unFlattenAppList = (flatAppList) => {
  /* Moves first container to top of list*/
  var firstContainerIndex = flatAppList.findIndex(
    (appListElement) => appListElement.containerID
  );
  var firstContainer = flatAppList.splice(firstContainerIndex, 1)[0];
  flatAppList.unshift(firstContainer);

  const unFlatList = flatAppList.reduce((prev, curr) => {
    if (curr.containerID) {
      return [...prev, { title: curr.title, containerID: curr.containerID }];
    }
    if (curr.appID) {
      const titleObject = prev[prev.length - 1];
      titleObject.apps
        ? titleObject.apps.push(curr.appID)
        : (titleObject["apps"] = [curr.appID]);
      return prev;
    }
  }, []);
  return unFlatList;
};

const mergeMementoObjects = (currentObject, newObject) => {
  // Do not update undoObject if path is aready changed
  newObject.undoObject = pickBy(newObject.undoObject, (value, key) => {
    return !keys(currentObject.undoObject).includes(key)
  })
  const mergedMementoObject = {};
  mergedMementoObject["mementoID"] = currentObject.mementoID;

  const currentUndoObject = unflatten(currentObject.undoObject);
  const newUndoObject = unflatten(newObject.undoObject);
  const currentRedoObject = unflatten(currentObject.redoObject);
  const newRedoObject = unflatten(newObject.redoObject);

  const mergedUndoObject = mergeWith(
    currentUndoObject,
    newUndoObject,
    (currentObjectProperty, newObjectProperty) =>
      isArray(newObjectProperty) ? newObjectProperty : undefined
  );
  const mergedRedoObject = mergeWith(
    currentRedoObject,
    newRedoObject,
    (currentObjectProperty, newObjectProperty) =>
      isArray(newObjectProperty) ? newObjectProperty : undefined
  );

  mergedMementoObject["undoObject"] = flatten(mergedUndoObject, {
    delimiter: "/",
    safe: true,
  });
  mergedMementoObject["redoObject"] = flatten(mergedRedoObject, {
    delimiter: "/",
    safe: true,
  });

  return mergedMementoObject;
};

export {
  isNumber,
  toNumber,
  uuid,
  flattenAppList,
  unFlattenAppList,
  mergeMementoObjects,
};
