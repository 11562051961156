<template>
    <ion-button fill="none">
      <ion-icon slot="icon-only"></ion-icon>
    </ion-button>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
export default {
  name: "PlaceholderButtonInline",
  components: {
    IonButton,
    IonIcon,
  },
};
</script>

<style scoped>
</style>