<template>
    <ion-button
    fill="none"
      class="add-button"
    >
      <ion-icon slot="icon-only" color="danger" :icon="closeCircleOutline"></ion-icon>
    </ion-button>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
export default {
  name: "DeleteButtonInline",
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      closeCircleOutline,
    };
  },
};
</script>

<style scoped>
</style>