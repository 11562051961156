<template>
  <ion-accordion-group :value="itemHasName ? 'module-options' : null">
    <ion-accordion value="module-options">
      <ion-item slot="header" lines="full">
        <ion-label class="ion-text-nowrap"><h1>Valgliste</h1></ion-label>
      </ion-item>

      <ion-list
        slot="content"
        mode="ios"
        style="padding-left: 16px; padding-right: 16px"
      >
        <!-- Titel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Titel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event, 'titel')"
            name="titel"
            mode="ios"
            :value="itemList[itemID].titel"
          ></ion-input>
        </ion-item>

        <!-- Subtitel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Undertitel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event, 'subTitel')"
            name="subTitel"
            mode="ios"
            :value="itemList[itemID].subTitel"
          ></ion-input>
        </ion-item>

        <!-- SubItem input -->
        <ion-label position="stacked">Valgmuligheder</ion-label>
        <ion-reorder-group
          :disabled="false"
          @ionItemReorder="reorderList($event)"
        >
          <div
            v-for="(subItemID, index) in itemList[itemID].subItems"
            :key="subItemID"
          >
            <!-- Name edit -->
            <ion-item mode="ios">
              <DeleteButtonInline
                @click="deleteSubItem(subItemID, itemID, appID)"
                slot="start"
              ></DeleteButtonInline>

              <ion-input
                title="Navn"
                @ionInput="updateSubItemProperty($event, subItemID)"
                name="name"
                mode="ios"
                :value="subItemList[subItemID]?.name"
              ></ion-input>
              <ion-reorder mode="md" slot="end"></ion-reorder>
              <ExpandButton
                @click="toggleExpand(index)"
                :isExpanded="isExpanded[index]"
              ></ExpandButton>
            </ion-item>

            <!-- Reference edit -->
            <ion-item v-if="isExpanded[index]" mode="ios">
              <LinkButton
                slot="start"
                @click="toggleItemValueLinked($event, subItemID)"
                :isLinked="subItemList[subItemID]?.linked"
              ></LinkButton>
              <ion-label>Ref:</ion-label>
              <ion-input
                title="Referenceværdi"
                @ionInput="updateSubItemProperty($event, subItemID)"
                name="subItemValue"
                mode="ios"
                :value="subItemList[subItemID]?.subItemValue"
                :disabled="subItemList[subItemID]?.linked"
              ></ion-input>
            </ion-item>
          </div>
        </ion-reorder-group>
        <ion-item mode="ios" lines="none">
          <AddButtonInline @click="addSubItem()" slot="start"></AddButtonInline>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonReorder,
  IonReorderGroup,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/vue";
import { caretDownCircleOutline } from "ionicons/icons";
import ExpandButton from "@/views/components/buttons/ExpandButton.vue";
import LinkButton from "@/views/components/buttons/LinkButton.vue";
import AddButtonInline from "@/views/components/buttons/AddButtonInline.vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";

export default {
  name: "RadioSelectListSidebarEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonReorder,
    IonReorderGroup,
    IonAccordionGroup,
    IonAccordion,
    ExpandButton,
    LinkButton,
    AddButtonInline,
    DeleteButtonInline,
  },
  setup() {
    const { itemList, currentEdit, subItemList } = useStoreGetters();
    const { updateAppProperty, newSubItem, deleteSubItem } = useStoreSetters();

    const appID = computed(() => currentEdit.value.appID);
    const tabID = computed(() => currentEdit.value.tabID);
    const itemID = computed(() => currentEdit.value.itemID);

    const itemHasName = itemList.value[itemID.value].name ? true : false;

    const updateItemProperty = (event, property) => {
      console.log(event)
      const value = event.target.value;
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    const updateSubItemProperty = (event, subItemID) => {
      const property = event.target.name;
      const value = event.target.value;
      const updateObject = {};
      updateObject[`subItemList/${subItemID}/${property}`] = value;
      if (subItemList.value[subItemID].linked) {
        updateObject[`subItemList/${subItemID}/subItemValue`] = value;
      }
      updateAppProperty(updateObject, appID.value);
    };

    const addSubItem = () => {
      newSubItem(appID.value, tabID.value, itemID.value, {
        name: "",
        subItemValue: "",
        linked: true,
      });
    };

    const reorderList = (event) => {
      // close all
      isExpanded.value = isExpanded.fill(false);
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/subItems`] = event.detail.complete(
        itemList.value[itemID.value].subItems
      );
      updateAppProperty(updateObject, appID.value);
    };

    const isExpanded = reactive([]);
    watch(itemID, () => {
      isExpanded.value = isExpanded.fill(false);
    });
    const toggleExpand = (index) => {
      isExpanded[index] = isExpanded[index] ? false : true;
    };

    const toggleItemValueLinked = (event, subItemID) => {
      const newLinked = !subItemList.value[subItemID].linked;
      const updateObject = {};
      updateObject[`subItemList/${subItemID}/linked`] = newLinked;
      // Update ref to correspond to name if changed to linked: true
      if (newLinked) {
        const subItemName = subItemList.value[subItemID].name;
        updateObject[`subItemList/${subItemID}/subItemValue`] = subItemName;
      }
      updateAppProperty(updateObject, appID.value);
    };

    return {
      appID,
      itemHasName,
      itemID,
      itemList,
      subItemList,
      updateItemProperty,
      reorderList,
      addSubItem,
      deleteSubItem,
      caretDownCircleOutline,
      toggleExpand,
      isExpanded,
      toggleItemValueLinked,
      updateSubItemProperty,
    };
  },
};
</script>

<style>
</style>