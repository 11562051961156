<template>
  <div style="display: inline-block; vertical-align: top" :style="{ width: itemList[itemID]?.width ?? '100%' }">
    <!-- ItemListEdit -->
    <EmptyModule v-if="currentEdit.component == 'ItemListEdit'" :moduleText="itemList[itemID]?.name"></EmptyModule>

    <!-- ItemInfoEdit but other item selected -->
    <EmptyModule
      v-else-if="currentEdit.component == 'ItemInfoEdit' && currentEdit.itemID != itemID"
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit -->
    <div v-else-if="currentEdit.subItemID != null">
      <!-- Copy text button -->
      <ion-button
        v-if="subItemList[currentEdit.subItemID]?.showCopyButton"
        class="ion-no-padding ion-no-margin"
        style="position: absolute; right: 4px; top: 2px; z-index: 10"
        fill="none"
        ><ion-icon slot="icon-only" :icon="copyOutline"></ion-icon
      ></ion-button>
      <!-- Content -->
      <WYSIWYG
        :key="currentEdit.subItemID"
        :appID="currentEdit.appID"
        :subItemID="currentEdit.subItemID"
        :content="subItemList[currentEdit.subItemID]?.output"
        @update:content="updateSubItemProperty($event)"
        editorType="Full"
        placeholder="Skriv tekst her..."
      ></WYSIWYG>
    </div>

    <!-- SubItem not selected -->
    <div v-else>
      <EmptyModule moduleText="Vælg/tilføj output"></EmptyModule>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
import WYSIWYG from "@/views/components/WYSIWYG.vue";
import { copyOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/vue";

export default {
  name: "WYSIWYGOutputMainEdit",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    EmptyModule,
    WYSIWYG,
    IonButton,
    IonIcon,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const updateSubItemProperty = (content) => {
      const updateObject = {};
      updateObject[`subItemList/${currentEdit.value.subItemID}/output`] = content;
      updateAppProperty(updateObject, props.appID);
    };

    return {
      itemList,
      subItems,
      subItemList,
      updateSubItemProperty,
      editMode,
      currentEdit,
      copyOutline,
    };
  },
};
</script>

<style>
</style>