<template>
  <div style="display: inline-block; vertical-align: top" :style="{ width: itemList[itemID]?.width ?? '100%' }">
    <ion-accordion-group :multiple="true">
      <template v-for="subItemID in subItems" :key="subItemID">
        <ion-accordion v-if="evaluate(subItemList[subItemID]?.visibleRules)">
          <!-- Header -->
          <ion-item
            slot="header"
            style="border-radius: 8px; margin: 0 16px"
            :style="{
              '--background': subItemList[subItemID]?.titleBackgroundColor ?? '#000000',
              '--accordion-chevron': subItemList[subItemID]?.titleColor ?? '#FFFFFF',
            }"
          >
            <ion-label
              style="font-size: 1.2em"
              :style="{
                color: subItemList[subItemID]?.titleColor ?? '#FFFFFF',
              }"
              >{{ subItemList[subItemID]?.title }}</ion-label
            >
          </ion-item>

          <!-- Copy text button -->
          <ion-button
            v-if="subItemList[subItemID]?.showCopyButton"
            slot="content"
            @click="copyToClipboard($event)"
            class="ion-no-padding ion-no-margin"
            style="position: absolute; right: 30px; margin-top: -10px;"
            fill="none"
            ><ion-icon slot="icon-only" :icon="copyOutline"></ion-icon
          ></ion-button>

          <!-- Content -->
          <div
            slot="content"
            style="padding: 0 16px; margin: 0 16px"
            v-html="evaluateExpressions(parseHTML(subItemList[subItemID]?.output))"
          ></div>
        </ion-accordion>
      </template>
    </ion-accordion-group>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
import { useExpression } from "@/utilities/composables/useExpression";
import { copyOutline } from "ionicons/icons";
import { IonItem, IonLabel, IonAccordionGroup, IonAccordion, IonButton, IonIcon } from "@ionic/vue";
export default {
  name: "DropDownOutput",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    IonItem,
    IonLabel,
    IonAccordionGroup,
    IonAccordion,
    IonButton,
    IonIcon,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { evaluate } = useEvaluate();
    const { parseHTML, evaluateExpressions } = useExpression();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const copyToClipboard = (event) => {
      navigator.clipboard.writeText(event.target.nextElementSibling.textContent);
    };

    return {
      itemList,
      subItems,
      subItemList,
      editMode,
      currentEdit,
      evaluate,
      parseHTML,
      evaluateExpressions,
      copyOutline,
      copyToClipboard,
    };
  },
};
</script>

<style>
ion-icon.ion-accordion-toggle-icon {
  color: var(--accordion-chevron);
}
</style>