<template>
  <div
    v-for="(subRule, subRuleIndex) in ruleList[visibleRule.ruleID]?.subRules"
    :key="subRuleIndex"
  >
    <!-- SubRuleChainOperator -->
    <ion-item lines="none" v-if="subRuleIndex != 0">
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <SubRuleChainOperator
        style="width: 100%"
        :ruleID="visibleRule.ruleID"
        :subRuleIndex="subRuleIndex"
      ></SubRuleChainOperator>
    </ion-item>

    <!-- Select first operand -->
    <ion-item lines="none">
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <DeleteButtonInline
        @click="deleteCurrentSubRule($event, visibleRule.ruleID, subRuleIndex)"
        slot="start"
      ></DeleteButtonInline>

      <ion-select
        style="flex: 1 1 100%"
        @ionChange="updateRuleProperty($event, visibleRule.ruleID)"
        :interface-options="subRulePopoverOptions"
        interface="popover"
        placeholder="Variabel"
        :name="`subRules/${subRuleIndex}/firstOperand`"
        :value="subRule.firstOperand"
        :key="subRule.firstOperand"
      >
        <ion-select-option
          v-for="itemID in appItems"
          :key="itemID"
          :value="itemID"
          >{{ itemList[itemID]?.name }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <!-- Select rule operator -->
    <ion-item lines="none">
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <SubRuleOperator
        style="width: 100%"
        :ruleID="visibleRule.ruleID"
        :subRuleIndex="subRuleIndex"
      ></SubRuleOperator>
    </ion-item>

    <!-- Select second operand -->
    <ion-item
      v-show="subRule.operator != 'notNil' && subRule.operator != 'isNil'"
      lines="none"
    >
      <!-- Radio select -->
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
      <ion-select
        v-if="subRule.operator == '==='"
        style="flex: 1 1 100%"
        @ionChange="updateRuleProperty($event, visibleRule.ruleID)"
        :name="`subRules/${subRuleIndex}/secondOperand`"
        :interface-options="subRulePopoverOptions"
        interface="popover"
        placeholder="Vælg..."
        :value="subRule.secondOperand"
        :key="Math.random(1)"
      >
        <ion-select-option
          v-for="subItemID in itemList[subRule.firstOperand]?.subItems"
          :key="subItemID"
          :value="subItemID"
          >{{
            subItemList[subItemID]?.linked
              ? subItemList[subItemID]?.name
              : `${subItemList[subItemID]?.name} (${subItemList[subItemID]?.subItemValue})`
          }}</ion-select-option
        >
      </ion-select>

      <!-- Checkbox select -->
      <ion-select
        v-else-if="
          subRule.operator == 'includes' || subRule.operator == 'exactMatch'
        "
        style="flex: 1 1 100%"
        @ionChange="updateRuleProperty($event, visibleRule.ruleID)"
        :name="`subRules/${subRuleIndex}/secondOperand`"
        placeholder="Vælg..."
        :value="subRule.secondOperand"
        :key="Math.random(2)"
        :multiple="true"
      >
        <ion-select-option
          v-for="subItemID in itemList[subRule.firstOperand]?.subItems"
          :key="subItemID"
          :value="subItemID"
          >{{ subItemList[subItemID]?.name }}</ion-select-option
        >
      </ion-select>

      <!-- Numeric and slider -->
      <ion-input
        v-else
        @ionInput="updateRuleProperty($event, visibleRule.ruleID)"
        :name="`subRules/${subRuleIndex}/secondOperand`"
        mode="ios"
        :value="subRule.secondOperand"
        placeholder="Værdi"
      ></ion-input>
    </ion-item>
  </div>

  <!-- Add new subrule -->
  <ion-item lines="none">
    <PlaceholderButtonInline slot="start"></PlaceholderButtonInline>
    <AddButtonInline
      slot="start"
      @click="addSubRule($event, visibleRule.ruleID)"
    ></AddButtonInline>
  </ion-item>
</template>

<script>
import { computed, ref } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { filter } from "lodash";

import { IonItem, IonSelect, IonSelectOption, IonInput } from "@ionic/vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";
import AddButtonInline from "@/views/components/buttons/AddButtonInline.vue";
import SubRuleOperator from "@/views/components/sub-rules/SubRuleOperator.vue";
import SubRuleChainOperator from "@/views/components/sub-rules/SubRuleChainOperator.vue";
import PlaceholderButtonInline from "@/views/components/buttons/PlaceholderButtonInline.vue";

export default {
  name: "SubRules",
  props: {
    visibleRule: {
      type: Object,
    },
  },
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    IonInput,
    DeleteButtonInline,
    AddButtonInline,
    SubRuleOperator,
    SubRuleChainOperator,
    PlaceholderButtonInline,
  },
  setup() {
    const { appInfo, tabList, ruleList, appID, itemList, subItemList } =
      useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const myValue = ref("en");

    const appItems = computed(() =>
      appInfo.value[appID.value].appTabs.reduce((acc, tabID) => {
        var inputTabItems = filter(
          tabList.value[tabID].tabItems,
          (itemID) => itemList.value[itemID].type == "input"
        );
        return [...acc, ...(inputTabItems ?? [])];
      }, [])
    );

    const updateRuleProperty = (event, ruleID) => {
      const property = event.target.name;
      const value = event.target.value;
      const updateObject = {};
      updateObject[`ruleList/${ruleID}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    const addSubRule = (event, ruleID) => {
      const newSubRules = [
        ...(ruleList.value[ruleID].subRules ?? []),
        { chainOperator: "&&" },
      ];
      const updateObject = {};
      updateObject[`ruleList/${ruleID}/subRules`] = newSubRules;
      updateAppProperty(updateObject, appID.value);
    };

    const deleteCurrentSubRule = (event, ruleID, subRuleIndex) => {
      const newSubRules = ruleList.value[ruleID].subRules.filter(
        (subRule, index) => index != subRuleIndex
      );
      const updateObject = {};
      updateObject[`ruleList/${ruleID}/subRules`] = newSubRules;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      appItems,
      itemList,
      subItemList,
      ruleList,
      updateRuleProperty,
      addSubRule,
      deleteCurrentSubRule,
      subRulePopoverOptions: {},
      myValue,
    };
  },
};
</script>

<style></style>