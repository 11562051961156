<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">Velkomst besked</ion-label>
      </ion-list-header>
    </ion-list>
  </ion-card>
</template>

<script>
import {
  IonLabel,
  IonList,
  IonListHeader,
  IonCard,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";

export default {
  name: "MainWelcomeEdit",
  components: {
    IonLabel,
    IonList,
    IonListHeader,
    IonCard,
    CloseButton,
  },
  setup() {
    return {
    };
  },
};
</script>

<style>
</style>