<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <!-- ItemListEdit -->
    <EmptyModule
      v-if="currentEdit.component == 'ItemListEdit'"
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit but other item selected -->
    <EmptyModule
      v-else-if="
        currentEdit.component == 'ItemInfoEdit' && currentEdit.itemID != itemID
      "
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit -->

    <!-- Type not selected -->
    <EmptyModule
      v-else-if="
        currentEdit.subItemID != null &&
        !subItemList[currentEdit.subItemID]?.type
      "
      moduleText="Vælg diagramtype"
    ></EmptyModule>

    <!-- Type selected -->
    <div v-else-if="currentEdit.subItemID != null">
      <ion-accordion-group :multiple="true">
        <!-- ContentLabel -->
        <ion-accordion>
          <ion-item slot="header">
            <ion-label
              ><h1>
                Data
                <ion-icon
                  v-if="validChartDataInput"
                  :icon="checkmarkCircleOutline"
                  style="color: green; vertical-align: text-bottom"
                ></ion-icon
                ><ion-icon
                  v-if="!validChartDataInput"
                  :icon="closeCircleOutline"
                  style="color: red; vertical-align: text-bottom"
                ></ion-icon></h1
            ></ion-label>
          </ion-item>

          <!-- Content Input -->
          <div slot="content">
            <WYSIWYG
              :appID="currentEdit.appID"
              :subItemID="currentEdit.subItemID"
              :content="subItemList[currentEdit.subItemID]?.chartData"
              @update:content="updateSubItemProperty($event, 'chartData')"
              editorType="Code"
              placeholder="Skriv JSON her..."
            ></WYSIWYG>
          </div>
        </ion-accordion>

        <!-- Options Label -->
        <ion-accordion>
          <ion-item slot="header">
            <ion-label
              ><h1>
                Options
                <ion-icon
                  v-if="validOptionsInput"
                  :icon="checkmarkCircleOutline"
                  style="color: green; vertical-align: text-bottom"
                ></ion-icon
                ><ion-icon
                  v-if="!validOptionsInput"
                  :icon="closeCircleOutline"
                  style="color: red; vertical-align: text-bottom"
                ></ion-icon></h1
            ></ion-label>
          </ion-item>

          <!-- Options Input -->
          <div slot="content">
            <WYSIWYG
              :appID="currentEdit.appID"
              :subItemID="currentEdit.subItemID"
              :content="subItemList[currentEdit.subItemID]?.options"
              @update:content="updateSubItemProperty($event, 'options')"
              editorType="Code"
              placeholder="Skriv JSON her..."
            ></WYSIWYG>
          </div>
        </ion-accordion>

        <!-- Background Color Label -->
        <ion-accordion value="background-color">
          <ion-item slot="header">
            <ion-label
              ><h1>
                Baggrundsfarver
                <ion-icon
                  v-if="validBackgroundColorInput"
                  :icon="checkmarkCircleOutline"
                  style="color: green; vertical-align: text-bottom"
                ></ion-icon
                ><ion-icon
                  v-if="!validBackgroundColorInput"
                  :icon="closeCircleOutline"
                  style="color: red; vertical-align: text-bottom"
                ></ion-icon></h1
            ></ion-label>
          </ion-item>

          <!-- Background Color Input-->
          <div slot="content">
            <WYSIWYG
              :appID="currentEdit.appID"
              :subItemID="currentEdit.subItemID"
              :content="subItemList[currentEdit.subItemID]?.backgroundColors"
              @update:content="
                updateSubItemProperty($event, 'backgroundColors')
              "
              editorType="Code"
              placeholder="Skriv JSON her..."
            ></WYSIWYG>
          </div>
        </ion-accordion>

        <!-- Chart Label -->
        <ion-accordion>
          <ion-item slot="header">
            <ion-label><h1>Diagram</h1></ion-label>
          </ion-item>

          <!-- Chart -->
          <div slot="content">
            <component
              v-if="!error"
              :is="subItemList[currentEdit.subItemID]?.type"
              style="padding: 0 16px; position: relative; margin: auto"
              :style="{
                height: subItemList[currentEdit.subItemID]?.height + 'px',
                width: subItemList[currentEdit.subItemID]?.width + '%',
              }"
              :subItemID="currentEdit.subItemID"
            ></component>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </div>

    <!-- SubItem not selected -->
    <div v-else>
      <EmptyModule moduleText="Vælg/tilføj output"></EmptyModule>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onErrorCaptured } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
import { useExpression } from "@/utilities/composables/useExpression";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
import BarChart from "@/views/components/charts/BarChart";
import LineChart from "@/views/components/charts/LineChart.vue";
import ScatterChart from "@/views/components/charts/ScatterChart.vue";
import LineScatterChart from "@/views/components/charts/LineScatterChart.vue";
import WYSIWYG from "@/views/components/WYSIWYG.vue";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";

export default {
  name: "ChartOutputMainEdit",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    BarChart,
    LineChart,
    ScatterChart,
    LineScatterChart,
    WYSIWYG,
    EmptyModule,
    IonItem,
    IonLabel,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();
    const { evaluate } = useEvaluate();
    const { parseHTML, evaluateExpressions } = useExpression();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const error = ref(false);

    const updateSubItemProperty = (value, property) => {
      const updateObject = {};
      updateObject[`subItemList/${currentEdit.value.subItemID}/${property}`] =
        value;
      updateAppProperty(updateObject, props.appID);
    };

    const validChartDataInput = computed(() => {
      try {
        JSON.parse(
          evaluateExpressions(
            parseHTML(subItemList.value[currentEdit.value.subItemID]?.chartData)
          ).replaceAll(/<[^>]+>|&nbsp;/g, "")
        );
        return true;
      } catch (error) {
        return false;
      }
    });

    const validOptionsInput = computed(() => {
      try {
        JSON.parse(
          evaluateExpressions(
            parseHTML(subItemList.value[currentEdit.value.subItemID]?.options)
          ).replaceAll(/<[^>]+>|&nbsp;/g, "")
        );
        return true;
      } catch (error) {
        return false;
      }
    });

    const validBackgroundColorInput = computed(() => {
      try {
        JSON.parse(
          evaluateExpressions(
            parseHTML(
              subItemList.value[currentEdit.value.subItemID]?.backgroundColors
            )
          ).replaceAll(/<[^>]+>|&nbsp;/g, "")
        );
        return true;
      } catch (error) {
        return false;
      }
    });

    onErrorCaptured((err, target, info) => {
      error.value = true;
      console.log("Error:", err.message,"Target:", target, "Info:", info)
      return false;
    });

    // Reset error when changing an item property
    watch(subItems, () => {
      if(error.value) error.value = false;
    }, {flush: 'post'});

    return {
      validChartDataInput,
      validOptionsInput,
      validBackgroundColorInput,
      itemList,
      subItems,
      subItemList,
      editMode,
      currentEdit,
      checkmarkCircleOutline,
      closeCircleOutline,
      evaluate,
      parseHTML,
      evaluateExpressions,
      updateSubItemProperty,
      error,
    };
  },
};
</script>

<style>
</style>