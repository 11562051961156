<template>
  <div>
    <ion-button
      :id="
        'subrule-chain-operator-select-popover-id-' +
        ruleID +
        '-' +
        subRuleIndex
      "
      color="medium"
      fill="solid"
      size="small"
      expand="block"
      class="subrule-chain-operator-button"
      >{{ subRuleChainOperatorDisplay }}</ion-button
    >
    <ion-popover
      :trigger="
        'subrule-chain-operator-select-popover-id-' +
        ruleID +
        '-' +
        subRuleIndex
      "
      :dismiss-on-select="true"
    >
      <ion-content>
        <ion-list>
          <ion-item button @click="updateSubRuleChainOperator('&&')"
            >OG</ion-item
          >
          <ion-item button @click="updateSubRuleChainOperator('||')"
            >ELLER</ion-item
          >
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonButton,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
} from "@ionic/vue";

export default {
  name: "SubRuleChainOperator",
  props: {
    ruleID: {
      type: String,
    },
    subRuleIndex: {
      type: Number,
    },
  },
  components: {
    IonButton,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
  },
  setup(props) {
    const { appID, ruleList } = useStoreGetters();

    const { updateAppProperty } = useStoreSetters();

    const subRuleChainOperator = computed(
      () =>
        ruleList.value[props.ruleID].subRules[props.subRuleIndex].chainOperator
    );
    const subRuleChainOperatorDisplay = computed(() => {
      switch (subRuleChainOperator.value) {
        case "&&":
          return "OG";
        case "||":
          return "ELLER";
        default:
          return null;
      }
    });
    const updateSubRuleChainOperator = (operator) => {
      const updateObject = {};
      const refURL = `ruleList/${props.ruleID}/subRules/${props.subRuleIndex}/chainOperator`;
      updateObject[refURL] = operator;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      subRuleChainOperatorDisplay,
      updateSubRuleChainOperator,
    };
  },
};
</script>

<style scoped>
</style>