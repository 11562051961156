<template>
  <transition name="fade-in">
    <ion-button fill="none" class="link-button" >
      <ion-icon slot="icon-only" :icon="isLinked ? link : unlink"></ion-icon>
    </ion-button>
  </transition>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { link, unlink } from "ionicons/icons";
export default {
  name: "LinkButton",
  props: {
      isLinked: Boolean,
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      link,
      unlink,
    };
  },
};
</script>

<style scoped>
/* https://github.com/ionic-team/ionic-framework/issues/23331 */
.link-button {
  pointer-events: auto !important;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>