<template>
  <ion-card class="ion-padding">
    <CloseButton></CloseButton>
    <ion-accordion-group :value="accordionValue" @ionChange.self="accordionValue = $event.target.value">
      <ion-accordion value="module-information">
        <ion-item slot="header" lines="full">
          <ion-label class="ion-text-nowrap"><h1>Modul information</h1></ion-label>
        </ion-item>

        <ion-list mode="ios" slot="content">
          <!-- Name input -->
          <ion-item mode="ios">
            <ion-label position="stacked">Navn</ion-label>
            <ion-input
              @ionInput="updateItemProperty($event, 'name')"
              name="name"
              mode="ios"
              clear-input
              :value="itemList[itemID]?.name"
            ></ion-input>
          </ion-item>

          <!-- Type input -->
          <ion-item>
            <ion-label position="stacked">Type</ion-label>
            <ion-select
              @ionChange="updateItemType($event)"
              :interface-options="typePopoverOptions"
              interface="popover"
              placeholder="Vælg"
              name="type"
              :value="itemList[itemID]?.type"
              :key="itemList[itemID]?.type"
            >
              <ion-select-option value="input">Input</ion-select-option>
              <ion-select-option value="output">Output</ion-select-option>
            </ion-select>
          </ion-item>

          <!-- Template Component input -->
          <ion-item>
            <ion-label position="stacked">Modul</ion-label>
            <ion-select
              @ionChange="updateItemProperty($event, 'templateComponent')"
              :interface-options="templateComponentPopoverOptions"
              interface="popover"
              placeholder="Vælg"
              name="templateComponent"
              :value="itemList[itemID]?.templateComponent"
              :key="itemList[itemID]?.templateComponent"
              :disabled="itemList[itemID]?.type == null"
            >
              <ion-select-option v-if="itemList[itemID]?.type == 'input'" value="RadioSelectList"
                >Valgliste</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'input'" value="CheckboxSelectList"
                >Tjekliste</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'input'" value="NumberInput"
                >Numerisk</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'input'" value="SliderInput">Slider</ion-select-option>
              <ion-select-option v-if="itemList[itemID]?.type == 'output'" value="InfoBoxOutput"
                >Info-boks</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'output'" value="DropDownOutput"
                >Drop-down tekst</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'output'" value="WYSIWYGOutput"
                >WYSIWYG</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'output'" value="ChartOutput"
                >Diagram</ion-select-option
              >
              <ion-select-option v-if="itemList[itemID]?.type == 'output'" value="HTMLOutput">HTML</ion-select-option>
            </ion-select>
          </ion-item>

          <!-- Input width input -->
          <div>
            <ion-label position="stacked">Bredde</ion-label>
            <ion-item mode="ios">
              <ion-segment
                @ionChange="updateItemProperty($event, 'width')"
                mode="ios"
                scrollable="true"
                :value="itemList[itemID]?.width"
                :key="itemList[itemID]?.width"
              >
                <ion-segment-button value="32%">
                  <ion-label>33 %</ion-label>
                </ion-segment-button>
                <ion-segment-button value="49%">
                  <ion-label>50 %</ion-label>
                </ion-segment-button>
                <ion-segment-button value="65%">
                  <ion-label>66 %</ion-label>
                </ion-segment-button>
                <ion-segment-button value="100%">
                  <ion-label>100 %</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-item>
          </div>

          <!-- Visible input -->
          <IsVisible :visibleRulesArrayPath="['itemList', itemID, 'visibleRules']"></IsVisible>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>
    <!-- Edit Component -->
    <component :is="itemList[itemID]?.templateComponent + 'SidebarEdit'"></component>

    <ion-button @click="deleteCurrentItem" color="danger" expand="full" fill="solid" class="ion-margin"
      >Slet</ion-button
    >
  </ion-card>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { ulid } from "ulid";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonListHeader,
  IonButton,
  IonCard,
  IonSelect,
  IonSelectOption,
  IonSegment,
  IonSegmentButton,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/vue";
import RadioSelectListSidebarEdit from "@/views/apps/right-sidebar/components/input/RadioSelectListSidebarEdit.vue";
import CheckboxSelectListSidebarEdit from "@/views/apps/right-sidebar/components/input/CheckboxSelectListSidebarEdit.vue";
import NumberInputSidebarEdit from "@/views/apps/right-sidebar/components/input/NumberInputSidebarEdit.vue";
import SliderInputSidebarEdit from "@/views/apps/right-sidebar/components/input/SliderInputSidebarEdit.vue";
import DropDownOutputSidebarEdit from "@/views/apps/right-sidebar/components/output/DropDownOutputSidebarEdit.vue";
import InfoBoxOutputSidebarEdit from "@/views/apps/right-sidebar/components/output/InfoBoxOutputSidebarEdit.vue";
import WYSIWYGOutputSidebarEdit from "@/views/apps/right-sidebar/components/output/WYSIWYGOutputSidebarEdit.vue";
import ChartOutputSidebarEdit from "@/views/apps/right-sidebar/components/output/ChartOutputSidebarEdit.vue";
import HTMLOutputSidebarEdit from "@/views/apps/right-sidebar/components/output/HTMLOutputSidebarEdit.vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";
import IsVisible from "@/views/components/IsVisible.vue";

export default {
  name: "ItemInfoEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonListHeader,
    IonButton,
    IonCard,
    IonSelect,
    IonSelectOption,
    IonSegment,
    IonSegmentButton,
    IonAccordionGroup,
    IonAccordion,
    RadioSelectListSidebarEdit,
    CheckboxSelectListSidebarEdit,
    NumberInputSidebarEdit,
    SliderInputSidebarEdit,
    DropDownOutputSidebarEdit,
    HTMLOutputSidebarEdit,
    InfoBoxOutputSidebarEdit,
    WYSIWYGOutputSidebarEdit,
    ChartOutputSidebarEdit,
    CloseButton,
    IsVisible,
  },
  setup() {
    const { appID, itemList, currentEdit } = useStoreGetters();
    const { updateAppProperty, deleteItem } = useStoreSetters();

    const tabID = computed(() => currentEdit.value.tabID);
    const itemID = computed(() => currentEdit.value.itemID);

    /* Accordion handling */
    const accordionValue = ref();
    watch(
      itemID,
      () => {
        accordionValue.value = itemList.value[itemID.value]?.name ? null : "module-information";
      },
      { immediate: true }
    );

    const updateItemProperty = (event, property) => {
      const value = event.target.value;
      const mementoID = property == "name" ? null : ulid()
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value, mementoID);
    };

    const updateItemType = (event) => {
      const newItemType = event.target.value;
      if (newItemType == itemList.value[itemID.value]?.type) return;
      const updateObject = {};
      // Keeps name, titel, subTitel and visibleRules at type change
      const value = {
        name: itemList.value[itemID.value]?.name ?? null,
        title: itemList.value[itemID.value]?.title ?? null,
        subTitel: itemList.value[itemID.value]?.subTitel ?? null,
        type: newItemType,
        templateComponent: null,
        visibleRules: itemList.value[itemID.value].visibleRules ?? null,
      };
      updateObject[`itemList/${itemID.value}`] = value;
      updateAppProperty(updateObject, appID.value, ulid());
    };

    const deleteCurrentItem = () => {
      deleteItem(itemID.value, tabID.value, appID.value);
    };

    return {
      accordionValue,
      appID,
      itemID,
      itemList,
      currentEdit,
      updateItemProperty,
      deleteCurrentItem,
      updateItemType,
      typePopoverOptions: {},
      templateComponentPopoverOptions: {},
    };
  },
};
</script>

<style>
</style>