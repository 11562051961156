<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">Fane information</ion-label>
      </ion-list-header>

      <ion-item mode="ios">
        <ion-label position="stacked">Navn</ion-label>
        <ion-input @ionInput="updateInputValue($event.target.value, 'name')" name="name" mode="ios" clear-input
          :value="tabList[tabID]?.name"></ion-input>
      </ion-item>

      <!-- Icon Picker -->
      <ion-item mode="ios">
        <ion-label position="stacked">Icon</ion-label>
        <IconPicker :iconName="tabList[tabID]?.iconName" @iconNameChange="updateInputValue($event, 'iconName')">
        </IconPicker>
      </ion-item>

      <!-- Show tab -->
      <IsVisible :visibleRulesArrayPath="['tabList', tabID, 'visibleRules']"></IsVisible>
    </ion-list>

    <!-- Delete tab -->
    <ion-button @click="deleteCurrentTab" color="danger" expand="full" fill="solid" class="ion-margin">Slet</ion-button>
  </ion-card>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { ulid } from "ulid";
import IconPicker from "@/views/components/IconPicker";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonListHeader,
  IonButton,
  IonCard,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";
import IsVisible from "@/views/components/IsVisible.vue";

export default {
  name: "TabInfoEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonListHeader,
    IonButton,
    IonCard,
    CloseButton,
    IsVisible,
    IconPicker,
  },
  setup() {
    const { appID, currentEdit, tabList } = useStoreGetters();
    const { updateAppProperty, deleteTab } = useStoreSetters();

    const tabID = computed(() => currentEdit.value.tabID);

    const updateInputValue = (value, property) => {
      const mementoID = property == "name" ? null : ulid()
      const updateObject = {};
      updateObject[`tabList/${tabID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value, mementoID);
    };

    const deleteCurrentTab = () => {
      deleteTab(tabID.value, appID.value);
    };

    return {
      tabID,
      tabList,
      updateInputValue,
      deleteCurrentTab,
    };
  },
};
</script>

<style>
</style>