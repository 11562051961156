<template>
  <div>
    <ion-button
      :id="'subrule-operator-select-popover-id-' + ruleID + '-' + subRuleIndex"
      color="medium"
      fill="outline"
      expand="block"
      size="small"
      class="rule-operator-button"
      >{{ subRuleOperatorDisplay }}</ion-button
    >
    <ion-popover
      :trigger="
        'subrule-operator-select-popover-id-' + ruleID + '-' + subRuleIndex
      "
      :dismiss-on-select="true"
    >
      <ion-content>
        <ion-list>
          <ion-item
            v-for="operatorElement in subRuleOperatorArray"
            :key="operatorElement.operator"
            button
            @click="updateSubRuleOperator(operatorElement.operator)"
          >
            {{ operatorElement.name }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { uniqueId } from "lodash";
import {
  IonButton,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
} from "@ionic/vue";

export default {
  name: "SubRuleChainOperator",
  props: {
    ruleID: {
      type: String,
    },
    subRuleIndex: {
      type: Number,
    },
  },
  components: {
    IonButton,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
  },
  setup(props) {
    const { appID, ruleList, itemList } = useStoreGetters();

    const { updateAppProperty } = useStoreSetters();

    const subRule = computed(
      () => ruleList.value[props.ruleID].subRules[props.subRuleIndex]
    );

    const subRuleOperatorDisplay = computed(() => {
      switch (subRule.value.operator) {
        case "===":
          return "VALGT SOM";
        case "notNil":
          return "UDFYLDT";
        case "isNil":
          return "IKKE UDFYLDT";
        case "==":
          return "LIG MED";
        case "!=":
          return "IKKE LIG MED";
        case ">":
          return "STØRRE END";
        case "<":
          return "MINDRE END";
        case ">=":
          return "STØRRE/LIG MED";
        case "<=":
          return "MINDRE/LIG MED";
        case "includes":
          return "INKLUDERER";
        case "exactMatch":
          return "VALGT SOM";
        case "checked":
          return "ANTAL VALGTE";
        default:
          return "Vælg...";
      }
    });

    const firstOperandInputType = computed(
      () => itemList.value[subRule.value.firstOperand].templateComponent
    );

    const subRuleOperatorArray = computed(() => {
      switch (firstOperandInputType.value) {
        case "NumberInput":
        case "SliderInput":
          return [
            { operator: "notNil", name: "Udfyldt" },
            { operator: "isNil", name: "Ikke udfyldt" },
            { operator: "==", name: "Lig med" },
            { operator: "!=", name: "Ikke lig med" },
            { operator: ">", name: "Større end" },
            { operator: "<", name: "Mindre end" },
            { operator: ">=", name: "Større/lig med" },
            { operator: "<=", name: "Mindre/lig med" },
          ];
        case "RadioSelectList":
          return [
            { operator: "===", name: "Valgt som" },
            { operator: "notNil", name: "Udfyldt" },
            { operator: "isNil", name: "Ikke udfyldt" },
            { operator: "==", name: "Lig med" },
            { operator: "!=", name: "Ikke lig med" },
            { operator: ">", name: "Større end" },
            { operator: "<", name: "Mindre end" },
            { operator: ">=", name: "Større/lig med" },
            { operator: "<=", name: "Mindre/lig med" },
          ];
        case "CheckboxSelectList":
          return [
            { operator: "includes", name: "Inkluderer" },
            { operator: "exactMatch", name: "Valgt som" },
            { operator: "checked", name: "Antal valgte" },
            { operator: "notNil", name: "Udfyldt" },
            { operator: "isNil", name: "Ikke udfyldt" },
          ];
        default:
          return null;
      }
    });

    const updateSubRuleOperator = (operator) => {
      const updateObject = {};
      const refURL = `ruleList/${props.ruleID}/subRules/${props.subRuleIndex}/operator`;
      updateObject[refURL] = operator;
      updateAppProperty(updateObject, appID.value);
    };

    const triggerButtonID = ref(uniqueId("rule-operator-popover-id-"));

    return {
      subRuleOperatorDisplay,
      triggerButtonID,
      subRuleOperatorArray,
      updateSubRuleOperator,
    };
  },
};
</script>

<style scoped>
</style>