<template>
  <transition name="fade-in">
    <ion-button
    fill="none"
      class="add-button"
    >
      <ion-icon slot="icon-only" color="success" :icon="addCircleOutline"></ion-icon>
    </ion-button>
  </transition>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
export default {
  name: "AddButtonInline",
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      addCircleOutline,
    };
  },
};
</script>

<style scoped>
.add-button {
  --background: none;
  --background-hover: none;
}
/* .add-button {
  --padding-start: 0px;
  --padding-end: 0px;
  width: 48px;
  height: 48px;
  margin-right: 36px;
}

.add-button::part(native) {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}
 */
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>