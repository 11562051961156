import { firebaseAction } from "vuexfire";
import { firebaseDB, firebaseStorage } from "@/store/firebase";
import {
  set,
  get,
  join,
  includes,
  values,
  forEach,
  forEachRight,
  reduce,
  remove,
  merge,
  some,
  map,
  isEqual,
  mapValues,
  isEmpty,
} from "lodash";
import { flattenAppList, mergeMementoObjects } from "@/utilities/utilityFunctions";
import { ulid } from "ulid";
import { flatten } from "flat";

const appState = {
  namespaced: true,
  state: () => ({
    localDB: {
      //Populate when store is initialized
      itemValues: {
        /*         appID: {
                  itemID: value,
                  itemID: value,
                } */
      },
    },
    apps: {
      root: null,
      appInfo: null,
      appList: null,
      itemList: null,
      tabList: null,
      subItemList: null,
    },
    mementos: null,
  }),
  mutations: {
    setItemValue: (state, { appID, itemID, newValue }) => {
      set(state.localDB.itemValues, [appID, itemID], newValue);
    },
    updateItemValueIDs: (state) => {
      const prevItemValues = state.localDB.itemValues;
      const apps = state.apps;
      const appIDArray = flattenAppList(apps.appList).reduce((prev, curr) => {
        if (curr.appID) prev.push(curr.appID);
        return prev;
      }, []);
      forEach(appIDArray, (appID) => {
        //skip if no tabs defined
        if (apps.appInfo[appID].appTabs == null) return;
        var newAppItemValueIDs = apps.appInfo[appID].appTabs.reduce((acc, tabID) => {
          return (acc = [...acc, ...(apps.tabList[tabID]?.tabItems ?? [])]);
        }, []);
        // Adds new itemValues (only inputs)
        forEach(newAppItemValueIDs, (newItemID) => {
          if (!includes(prevItemValues[appID], newItemID) && apps.itemList[newItemID]?.type == "input") {
            set(prevItemValues, [appID, newItemID], null);
          }
        });
        // Removes itemValues not existing anymore
        forEach(prevItemValues[appID], (prevItemValue, prevItemID) => {
          if (!includes(newAppItemValueIDs, prevItemID)) delete prevItemValues[appID].prevItemID;
        });
      });
    },
  },
  actions: {
    bindStore: firebaseAction(async ({ commit, bindFirebaseRef }, { storeName }) => {
      await bindFirebaseRef("apps", firebaseDB.ref(storeName), {
        maxRefDepth: 5,
        reset: false,
      });
      commit("setStoreName", storeName, { root: true });
      // Load mementos if on stage
      if (storeName == "appsStage") {
        await bindFirebaseRef("mementos", firebaseDB.ref("mementos"), {
          reset: true,
        });
      }
      console.log(`Firestore ${storeName} initialized`);
    }),
    updateAppProperty: async ({ rootState, dispatch }, { updateObject, appID, mementoID }) => {
      // Prevents updates when changing to editMode: false
      if (rootState.UIState.apps.editMode) {
        const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
        const currentStoreName = rootState.UIState.apps.currentStoreName;
        const stateChange = await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
        // Return if no change in state
        if (!stateChange) return;
        firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
      }
    },
    saveMemento: async ({ rootState, state, dispatch }, { flatUpdateObject, appID, mementoID, currentEditObject }) => {
      if (!appID) appID = "root";
      const undoObject = reduce(
        flatUpdateObject,
        (acc, value, key) => {
          acc[key.replaceAll("/", ">>")] = get(state.apps, key.replaceAll("/", ".")) ?? "null";
          return acc;
        },
        {}
      );
      const redoObject = reduce(
        flatUpdateObject,
        (acc, value, key) => {
          acc[key.replaceAll("/", ">>")] = value ?? "null";
          return acc;
        },
        {}
      );
      const UIState = {};
      if (currentEditObject) {
        set(
          UIState,
          ["undo", "currentEdit"],
          mapValues(rootState.UIState.apps.currentEdit, (value) => value ?? "null")
        );
        set(
          UIState,
          ["redo", "currentEdit"],
          mapValues(currentEditObject, (value) => value ?? "null")
        );
      }

      if (isEqual(undoObject, redoObject)) return false;
      else {
        dispatch("_saveMemento", { undoObject, redoObject, UIState, appID, mementoID });
        return true;
      }
    },
    _saveMemento: async ({ rootState, state, commit }, { undoObject, redoObject, UIState, appID, mementoID }) => {
      const newMementoObject = {
        mementoID: mementoID,
        ...(!isEmpty(UIState) && { UIState: UIState }),
        undoObject: undoObject,
        redoObject: redoObject,
      };
      const undoArray = state.mementos[appID]?.undo ?? [];
      var newUndoArray = [];
      // If no mementoID and less than 1 sec from last save, merge menentoObject with previous saved
      if (!mementoID) {
        const mementoTimestamp = Date.now();
        const lastMementoTimestamp = rootState.UIState.apps.mementosData.lastMementoTimestamp;
        commit("setMementoTimestamp", mementoTimestamp, {
          root: true,
        });
        if (mementoTimestamp < lastMementoTimestamp + 1000) {
          const lastMementoID = rootState.UIState.apps.mementosData.lastMementoID;
          newUndoArray = map(undoArray, (mementoObject) => {
            if (mementoObject.mementoID == lastMementoID) {
              return mergeMementoObjects(mementoObject, newMementoObject);
            } else return mementoObject;
          });
        } else {
          newMementoObject.mementoID = ulid();
          newUndoArray = undoArray;
          if (newUndoArray.length > 1000) newUndoArray.shift();
          newUndoArray.push(newMementoObject);
          commit("setLastMementoID", newMementoObject.mementoID, {
            root: true,
          });
        }
      }
      // If mementoID exist check if it already exist and merge
      else if (some(undoArray, (mementoObject) => mementoObject.mementoID == mementoID)) {
        newUndoArray = map(undoArray, (mementoObject) => {
          if (mementoObject.mementoID == mementoID) return mergeMementoObjects(mementoObject, newMementoObject);
          else return mementoObject;
        });
      } else {
        // Else push new memento and delete old ones
        newUndoArray = undoArray;
        if (newUndoArray.length > 1000) newUndoArray.shift();
        newUndoArray.push(newMementoObject);
      }

      const updateObject = {};
      updateObject["undo"] = newUndoArray;
      updateObject["redo"] = null;
      console.log("Memento saved");
      return firebaseDB.ref(`mementos/${appID}`).update(updateObject);
    },
    undoChange: async ({ state, commit }, { appID }) => {
      if (!appID) appID = "root";
      var undoArray = state.mementos[appID].undo;
      const lastMementoObject = undoArray.pop();
      var redoArray = state.mementos[appID].redo ?? [];
      redoArray.push(lastMementoObject);

      const flatUpdateObject = reduce(
        lastMementoObject.undoObject,
        (acc, value, key) => {
          acc["appsStage/" + key.replaceAll(">>", "/")] = value == "null" ? null : value;
          return acc;
        },
        {}
      );
      const currentEdit = mapValues(get(lastMementoObject, ["UIState", "undo", "currentEdit"]), (value) =>
        value == "null" ? null : value
      );
      if (!isEmpty(currentEdit)) commit("setCurrentEdit", currentEdit, { root: true });

      flatUpdateObject[`mementos/${appID}/undo`] = undoArray;
      flatUpdateObject[`mementos/${appID}/redo`] = redoArray;
      return firebaseDB.ref().update(flatUpdateObject);
    },
    redoChange: async ({ state, commit }, { appID }) => {
      if (!appID) appID = "root";
      var redoArray = state.mementos[appID].redo;
      const lastMementoObject = redoArray.pop();
      var undoArray = state.mementos[appID].undo ?? [];
      undoArray.push(lastMementoObject);

      const flatUpdateObject = reduce(
        lastMementoObject.redoObject,
        (acc, value, key) => {
          acc["appsStage/" + key.replaceAll(">>", "/")] = value == "null" ? null : value;
          return acc;
        },
        {}
      );
      const currentEdit = mapValues(get(lastMementoObject, ["UIState", "redo", "currentEdit"]), (value) =>
        value == "null" ? null : value
      );
      if (!isEmpty(currentEdit)) commit("setCurrentEdit", currentEdit, { root: true });

      flatUpdateObject[`mementos/${appID}/redo`] = redoArray;
      flatUpdateObject[`mementos/${appID}/undo`] = undoArray;
      return firebaseDB.ref().update(flatUpdateObject);
    },
    deleteRule: async ({ rootState, state, dispatch }, { rulesArrayPath, ruleIndex, appID, mementoID }) => {
      if (!mementoID) mementoID = ulid();
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const updateObject = _deleteRuleObject(state, rulesArrayPath, ruleIndex);

      const flatUpdateObject = flatten(updateObject, {
        delimiter: "/",
        safe: true,
      });
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
      return firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    deleteSubItem: async ({ rootState, state, dispatch }, { subItemID, itemID, appID, mementoID }) => {
      if (!mementoID) mementoID = ulid();
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const updateObject = _deleteSubItemObject(state, subItemID, itemID);

      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // Prevents input from saving to firestore during deletion
      // Probably not nessecery after changed to ionInput
      // commit("setCurrentEdit", {}, { root: true });
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
      return firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    deleteItem: async ({ rootState, state, commit, dispatch }, { itemID, tabID, appID, mementoID }) => {
      if (!mementoID) mementoID = ulid();
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const updateObject = _deleteItemObject(state, itemID, tabID);

      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // Prevents input from saving to firestore during deletion
      commit("setCurrentEdit", {}, { root: true });
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
      return firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    deleteTab: async ({ rootState, state, commit, dispatch }, { tabID, appID, mementoID }) => {
      if (!mementoID) mementoID = ulid();
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const updateObject = _deleteTabObject(state, tabID, appID);

      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // Prevents input from saving to firestore during deletion
      commit("setCurrentEdit", {}, { root: true });
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
      return firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    deleteApp: async ({ rootState, state, commit, dispatch }, { appID, mementoID }) => {
      if (!mementoID) mementoID = ulid();
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const updateObject = _deleteAppObject(state, appID);

      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // Prevents input from saving to firestore during deletion
      commit("setCurrentEdit", {}, { root: true });
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID });
      return firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    newApp: async ({ rootState, commit, dispatch }, { containerIndex }) => {
      const updateObject = {};
      // AppInfo
      const appID = ulid();
      const appInfoTemplate = { name: "", description: "", slug: "" };
      updateObject[`appInfo/${appID}`] = appInfoTemplate;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // AppList
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const appListRef = firebaseDB.ref(`${currentStoreName}/appList`);
      const appListSnapshot = await appListRef.get();
      const newAppList = appListSnapshot.val() ?? [];
      if (!newAppList[containerIndex].apps) newAppList[containerIndex]["apps"] = [];
      newAppList[containerIndex].apps.push(appID);
      flatUpdateObject["appList"] = newAppList;
      // Save to memento and firebase
      const currentEditObject = { component: "AppInfoEdit", appID: appID };
      await dispatch("saveMemento", { flatUpdateObject, appID: "root", mementoID: ulid(), currentEditObject });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
      // Set current edit to new app
      commit("setCurrentEdit", currentEditObject, { root: true });
    },
    newTab: async ({ rootState, commit, dispatch }, { appID }) => {
      const updateObject = {};
      // TabInfo
      const tabID = ulid();
      const tabInfoTemplate = { name: "" };
      updateObject[`tabList/${tabID}`] = tabInfoTemplate;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // AppTabs
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const appTabs = firebaseDB.ref(`${currentStoreName}/appInfo/${appID}/appTabs`);
      const appTabsSnapshot = await appTabs.get();
      const newAppTabs = appTabsSnapshot.val() ?? [];
      newAppTabs.push(tabID);
      flatUpdateObject[`appInfo/${appID}/appTabs`] = newAppTabs;
      // Save to memento and firebase
      const currentEditObject = { component: "TabInfoEdit", tabID: tabID };
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid(), currentEditObject });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
      // Set current edit to new tab
      commit("setCurrentTabID", tabID, { root: true });
      commit("setCurrentEdit", currentEditObject, { root: true });
    },
    newItem: async ({ rootState, commit, dispatch }, { appID, tabID }) => {
      const updateObject = {};
      // ItemInfo
      const itemID = ulid();
      const itemInfoTemplate = { name: "" };
      updateObject[`itemList/${itemID}`] = itemInfoTemplate;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // TabItems
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const tabItemsRef = firebaseDB.ref(`${currentStoreName}/tabList/${tabID}/tabItems`);
      const tabItemsSnapshot = await tabItemsRef.get();
      const newTabItems = tabItemsSnapshot.val() ?? [];
      newTabItems.push(itemID);
      flatUpdateObject[`tabList/${tabID}/tabItems`] = newTabItems;
      // Save to memento and firebase
      const currentEditObject = { component: "ItemInfoEdit", appID: appID, tabID: tabID, itemID: itemID };
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid(), currentEditObject });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
      // Set current edit to new item
      commit("setCurrentEdit", currentEditObject, { root: true });
    },
    newSubItem: async ({ rootState, state, commit, dispatch }, { appID, tabID, itemID, subItemInfoTemplate }) => {
      const updateObject = {};
      // ItemInfo
      const subItemID = ulid();
      updateObject[`subItemList/${subItemID}`] = subItemInfoTemplate;
      updateObject[`subItemList/${subItemID}/parentTemplateComponent`] = state.apps.itemList[itemID].templateComponent;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // SubItems
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const subItemsRef = firebaseDB.ref(`${currentStoreName}/itemList/${itemID}/subItems`);
      const subItemsSnapshot = await subItemsRef.get();
      const newSubItems = subItemsSnapshot.val() ?? [];
      newSubItems.push(subItemID);
      flatUpdateObject[`itemList/${itemID}/subItems`] = newSubItems;
      // Save to memento and firebase
      const currentEditObject = {
        component: "ItemInfoEdit",
        appID: appID,
        tabID: tabID,
        itemID: itemID,
        subItemID: subItemID,
      };
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid(), currentEditObject });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
      // Set current edit to new subItem
      commit("setCurrentEdit", currentEditObject, { root: true });
    },
    newRule: async ({ rootState, dispatch }, { appID, visibleRulesArrayPath, ruleIndex }) => {
      const updateObject = {};
      // RuleInfo
      const ruleID = ulid();
      const visibleRulesPathURL = join(visibleRulesArrayPath, "/");
      const ruleInfoTemplate = {
        appID: appID,
        name: "",
        ruleURLs: [`${visibleRulesPathURL}/${ruleIndex}`],
      };
      updateObject[`ruleList/${ruleID}`] = ruleInfoTemplate;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // VisibleRules
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      const rulesRef = firebaseDB.ref(`${currentStoreName}/${visibleRulesPathURL}`);
      const rulesSnapshot = await rulesRef.get();
      const newRules = rulesSnapshot.val() ?? [];
      set(newRules, [ruleIndex], {
        chainOperator: "&&",
        ruleID: ruleID,
      });
      flatUpdateObject[visibleRulesPathURL] = newRules;
      // Save to memento and firebase
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid() });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    newRuleFromValues: async ({ state, rootState, dispatch }, { appID, visibleRulesArrayPath, ruleIndex }) => {
      const updateObject = {};
      // RuleInfo
      const ruleID = ulid();
      const visibleRulesPathURL = join(visibleRulesArrayPath, "/");
      const subRules = reduce(
        state.localDB.itemValues[appID],
        (acc, itemValue, itemID) => {
          if (itemValue) {
            return (acc = [
              ...acc,
              {
                chainOperator: "&&",
                firstOperand: itemID,
                operator: "===",
                secondOperand: itemValue,
              },
            ]);
          }
        },
        []
      );
      const ruleInfoTemplate = {
        appID: appID,
        name: "",
        ruleURLs: [`${visibleRulesPathURL}/${ruleIndex}`],
        subRules: subRules,
      };
      updateObject[`ruleList/${ruleID}`] = ruleInfoTemplate;
      const flatUpdateObject = flatten(updateObject, { delimiter: "/", safe: true });
      // VisibleRules
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      //const newRuleRef = await firebaseDB.ref(`${currentStoreName}/ruleList`).push(ruleInfoTemplate);
      const rulesRef = firebaseDB.ref(`${currentStoreName}/${visibleRulesPathURL}`);
      const rulesSnapshot = await rulesRef.get();
      const newRules = rulesSnapshot.val() ?? [];
      set(newRules, [ruleIndex], {
        chainOperator: "&&",
        ruleID: ruleID,
      });
      flatUpdateObject[visibleRulesPathURL] = newRules;
      // Save to memento and firebase
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid() });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    // TODO: should these also have flatten their flatUpdateObject? Probably not as properties are arrays
    selectRule: async ({ rootState, dispatch }, { appID, visibleRulesArrayPath, ruleIndex, ruleID }) => {
      const flatUpdateObject = {};
      const visibleRulesPathURL = join(visibleRulesArrayPath, "/");
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      /* ruleList */
      const ruleURLsRef = firebaseDB.ref(`${currentStoreName}/ruleList/${ruleID}/ruleURLs`);
      const ruleURLsSnapshot = await ruleURLsRef.get();
      const newRuleURLs = ruleURLsSnapshot.val();
      newRuleURLs.push(`${visibleRulesPathURL}/${ruleIndex}`);
      flatUpdateObject[`ruleList/${ruleID}/ruleURLs`] = newRuleURLs;
      //ruleURLsRef.set(newRuleURLs);
      /* visibleRules */
      const rulesRef = firebaseDB.ref(`${currentStoreName}/${visibleRulesPathURL}`);
      const rulesSnapshot = await rulesRef.get();
      const newRules = rulesSnapshot.val() ?? [];
      set(newRules, [ruleIndex], {
        chainOperator: "&&",
        ruleID: ruleID,
      });
      flatUpdateObject[visibleRulesPathURL] = newRules;
      // Save to memento and firebase
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid() });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    copyRule: async ({ state, rootState, dispatch }, { appID, visibleRulesArrayPath, ruleIndex, ruleID }) => {
      const flatUpdateObject = {};
      // RuleInfo
      const newRuleID = ulid();
      const visibleRulesPathURL = join(visibleRulesArrayPath, "/");
      const ruleInfoTemplate = state.apps.ruleList[ruleID];
      ruleInfoTemplate["ruleURLs"] = [`${visibleRulesPathURL}/${ruleIndex}`];
      flatUpdateObject[`ruleList/${newRuleID}`] = ruleInfoTemplate;
      // VisibleRules
      const currentStoreName = rootState.UIState.apps.currentStoreName;
      //const newRuleRef = await firebaseDB.ref(`${currentStoreName}/ruleList`).push(ruleInfoTemplate);
      const rulesRef = firebaseDB.ref(`${currentStoreName}/${visibleRulesPathURL}`);
      const rulesSnapshot = await rulesRef.get();
      const newRules = rulesSnapshot.val() ?? [];
      set(newRules, [ruleIndex], {
        chainOperator: "&&",
        ruleID: newRuleID,
      });
      flatUpdateObject[visibleRulesPathURL] = newRules;
      // Save to memento and firebase
      await dispatch("saveMemento", { flatUpdateObject, appID, mementoID: ulid() });
      firebaseDB.ref(`${currentStoreName}`).update(flatUpdateObject);
    },
    publishStage: async () => {
      const appsStageRef = firebaseDB.ref("appsStage");
      const appsStageSnapshot = await appsStageRef.get();
      const appsRef = firebaseDB.ref("apps");
      return appsRef.set(appsStageSnapshot.val());
    },
    uploadImage: async (context, { blobInfo, appID, subItemID }) => {
      const storageRef = firebaseStorage.ref();
      const imageExtension = blobInfo.blob().type.split("/").pop();
      const metadata = {
        customMetadata: {
          imageID: blobInfo.id(),
          appID: appID,
          ...(subItemID && { subItemID: subItemID }),
        },
      };
      const uploadRequest = storageRef
        .child(`images/${appID}/${blobInfo.id()}.${imageExtension}`)
        .put(blobInfo.blob(), metadata)
        .then((uploadTaskSnapshot) => {
          return uploadTaskSnapshot.ref.getDownloadURL().then((downloadURL) => {
            return {
              response: "SUCCESS",
              value: downloadURL,
            };
          });
        })
        .catch((error) => {
          return { response: "ERROR", value: error };
        });

      return uploadRequest;
    },
  },
  getters: {
    appIDFromSlug: (state) => (slug) => {
      var _appID;
      const appInfo = state.apps.appInfo;
      for (const appID in appInfo) {
        if (appInfo[appID].slug == slug) _appID = appID;
      }
      return _appID;
    },
    inputItems: (state) => (appID) => {
      var _inputItems = {};
      var appItemList = {};

      const itemList = state.apps.itemList;

      if (!appID) {
        appItemList = itemList;
      } else {
        const appTabs = state.apps.appInfo[appID].appTabs;
        const tabList = state.apps.tabList;
        var tabItems;
        for (var tabIndex = 0; tabIndex < appTabs.length; tabIndex++) {
          tabItems = tabList[appTabs[tabIndex]].tabItems;
          for (var itemIndex = 0; itemIndex < tabItems?.length; itemIndex++) {
            if (itemList[tabItems[itemIndex]].type == "input")
              appItemList[tabItems[itemIndex]] = itemList[tabItems[itemIndex]];
          }
        }
      }

      for (var itemID in appItemList) {
        if (appItemList[itemID].type == "input") _inputItems[itemID] = appItemList[itemID];
      }
      return _inputItems;
    },
    validInput: (state) => (appID) => {
      const appItemValues = state.localDB.itemValues[appID];
      const _validInput = values(appItemValues).every((value) => value != null);
      return _validInput;
    },
    getAppProperty: (state) => (arrayPath) => {
      return get(state.apps, arrayPath);
    },
  },
};

// Helper functions
const _deleteRuleObject = (state, rulesArrayPath, ruleIndex) => {
  var updateObject = {};
  const ruleID = get(state.apps, [...rulesArrayPath, ruleIndex, "ruleID"]);
  const newRules = get(state.apps, rulesArrayPath).filter((rule, index) => index != ruleIndex);
  const rulesPathURL = join(rulesArrayPath, "/");
  set(updateObject, rulesArrayPath, newRules);
  const ruleURLs = state.apps.ruleList[ruleID]?.ruleURLs ?? [];
  if (ruleURLs.length == 1) {
    set(updateObject, ["ruleList", ruleID], null);
  } else if (ruleURLs.length > 1) {
    const newRuleURLs = ruleURLs.filter((ruleURL) => ruleURL != `${rulesPathURL}/${ruleIndex}`);
    set(updateObject, ["ruleList", ruleID, "ruleURLs"], newRuleURLs);
  }
  return updateObject;
};

const _deleteSubItemObject = (state, subItemID, itemID) => {
  var updateObject = {};

  // Delete subItems visibleRules (backward iteration to keep array indices between deletes)
  const rulesArrayPath = ["subItemList", subItemID, "visibleRules"];
  forEachRight(state.apps.subItemList[subItemID].visibleRules, (visibleRule, ruleIndex) => {
    merge(updateObject, _deleteRuleObject(state, rulesArrayPath, ruleIndex));
  });

  // Delete subItem from list
  set(updateObject, ["subItemList", subItemID], null);

  // Delete subItem reference from itemList
  const newSubItems = state.apps.itemList[itemID].subItems.filter((subItem) => subItem != subItemID);
  set(updateObject, ["itemList", itemID, "subItems"], newSubItems);

  return updateObject;
};

const _deleteItemObject = (state, itemID, tabID) => {
  var updateObject = {};

  // Delete subItems (backward iteration to keep array indices between deletes)
  forEachRight(state.apps.itemList[itemID].subItems, (subItemID) => {
    merge(updateObject, _deleteSubItemObject(state, subItemID, itemID));
  });

  // Delete items visibleRules (backward iteration to keep array indices between deletes)
  const rulesArrayPath = ["itemList", itemID, "visibleRules"];
  forEachRight(state.apps.itemList[itemID].visibleRules, (visibleRule, ruleIndex) => {
    merge(updateObject, _deleteRuleObject(state, rulesArrayPath, ruleIndex));
  });

  // Delete item from list
  set(updateObject, ["itemList", itemID], null);

  // Delete item reference from tabList
  const newTabItems = state.apps.tabList[tabID].tabItems.filter((tabItemID) => tabItemID != itemID);
  set(updateObject, ["tabList", tabID, "tabItems"], newTabItems);

  return updateObject;
};

const _deleteTabObject = (state, tabID, appID) => {
  var updateObject = {};

  // Delete tab items (backward iteration to keep array indices between deletes)
  forEachRight(state.apps.tabList[tabID].tabItems, (itemID) => {
    merge(updateObject, _deleteItemObject(state, itemID, tabID));
  });

  // Delete tab visibleRules (backward iteration to keep array indices between deletes)
  const rulesArrayPath = ["tabList", tabID, "visibleRules"];
  forEachRight(state.apps.tabList[tabID].visibleRules, (visibleRule, ruleIndex) => {
    merge(updateObject, _deleteRuleObject(state, rulesArrayPath, ruleIndex));
  });

  // Delete tab from list
  set(updateObject, ["tabList", tabID], null);

  // Delete tab reference from appInfo
  const newAppTabs = state.apps.appInfo[appID].appTabs.filter((appTabID) => appTabID != tabID);
  set(updateObject, ["appInfo", appID, "appTabs"], newAppTabs);

  return updateObject;
};

const _deleteAppObject = (state, appID) => {
  var updateObject = {};

  // Delete app tabs (backward iteration to keep array indices between deletes)
  forEachRight(state.apps.appInfo[appID].appTabs, (tabID) => {
    merge(updateObject, _deleteTabObject(state, tabID, appID));
  });

  // Delete appInfo
  set(updateObject, ["appInfo", appID], null);

  // Delete app reference from appList
  const newAppList = state.apps.appList;
  newAppList.forEach((appListElement) => remove(appListElement.apps, (ID) => ID == appID));
  set(updateObject, ["appList"], newAppList);

  return updateObject;
};

export default appState;
