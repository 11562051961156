<template>
  <ion-accordion-group :value="itemHasName ? 'module-options' : null">
    <ion-accordion value="module-options">
      <ion-item slot="header" lines="full">
        <ion-label class="ion-text-nowrap"><h1>Slider input</h1></ion-label>
      </ion-item>

      <ion-list
        slot="content"
        mode="ios"
        style="padding-left: 16px; padding-right: 16px"
      >
        <!-- Titel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Titel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event.target.value, 'titel')"
            mode="ios"
            :value="itemList[itemID].titel"
          ></ion-input>
        </ion-item>

        <!-- Subtitel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Undertitel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event.target.value, 'subTitel')"
            mode="ios"
            :value="itemList[itemID].subTitel"
          ></ion-input>
        </ion-item>

        <!-- MinValue -->
        <ion-item mode="ios">
          <ion-label position="fixed">Minimum</ion-label>
          <ion-input
            @ionInput="updateItemProperty(toNumber($event.target.value), 'minValue')"
            inputmode="numeric"
            type="number"
            mode="ios"
            :value="itemList[itemID].minValue"
          ></ion-input>
        </ion-item>

        <!-- MaxValue -->
        <ion-item mode="ios">
          <ion-label position="fixed">Maksimum</ion-label>
          <ion-input
            @ionInput="updateItemProperty(toNumber($event.target.value), 'maxValue')"
            inputmode="numeric"
            type="number"
            mode="ios"
            :value="itemList[itemID].maxValue"
          ></ion-input>
        </ion-item>

        <!-- InitialValue -->
        <ion-item mode="ios">
          <ion-label position="fixed">Startværdi</ion-label>
          <ion-input
            @ionInput="updateItemProperty(toNumber($event.target.value), 'initialValue')"
            inputmode="numeric"
            type="number"
            mode="ios"
            :value="itemList[itemID].initialValue"
          ></ion-input>
        </ion-item>

        <!-- ShowPin -->
        <ion-item mode="ios">
          <ion-label position="fixed">Vis værdi</ion-label>
          <ion-toggle
            @ionChange="updateItemProperty($event.target.checked, 'showValue')"
            :checked="itemList[itemID].showValue"
            :key="itemList[itemID].showValue"
            mode="md"
          >
          </ion-toggle>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { toNumber } from "@/utilities/utilityFunctions"; 
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonToggle,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/vue";

export default {
  name: "SliderInputSidebarEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonToggle,
    IonAccordionGroup,
    IonAccordion,
  },
  setup() {
    const { appID, itemList, currentEdit } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const itemID = computed(() => currentEdit.value.itemID);

    const itemHasName = itemList.value[itemID.value].name ? true : false;

    const updateItemProperty = (value, property) => {
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      itemHasName,
      toNumber,
      itemID,
      itemList,
      updateItemProperty,
    };
  },
};
</script>

<style>
</style>