import { createStore } from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import appState from '@/store/appState';
import { appStatePlugin } from '@/store/appStatePlugin';
import _ from "lodash";

export default createStore({
    modules: { appState },
    plugins: [appStatePlugin],
    state: {
        userPreferences: {},
        UIState: {
            apps: {
                currentTabID: null,
                currentEdit: {
                    component: null,
                    appID: null,
                    tabID: null,
                    itemID: null,
                    subItemID: null,
                },
                editMode: false,
                currentStoreName: "apps",
                accordionValue: null,
                mementosData: {
                    lastMementoTimestamp: null,
                    lastMementoID: null,
                }
            }
        },
    },
    mutations: {
        ...vuexfireMutations,
        setCurrentTabID: (state, tabID) => {
            state.UIState.apps.currentTabID = tabID;
        },
        setCurrentEdit: (state, currentEdit) => {
            state.UIState.apps.currentEdit.component = currentEdit.component;
            state.UIState.apps.currentEdit.appID = currentEdit.appID;
            state.UIState.apps.currentEdit.tabID = currentEdit.tabID;
            state.UIState.apps.currentEdit.itemID = currentEdit.itemID;
            state.UIState.apps.currentEdit.subItemID = currentEdit.subItemID;
        },
        setUIStateProperty: (state, { path, value }) => {
            _.set(state.UIState, path, value);
        },
        setStoreName: (state, storeName) => {
            state.UIState.apps.currentStoreName = storeName;
        },
        setAccordionValue: (state, accordionValue) => {
            state.UIState.apps.accordionValue = accordionValue;
        },
        setMementoTimestamp: (state, mementoTimestamp) => {
            state.UIState.apps.mementosData.lastMementoTimestamp = mementoTimestamp
        },
        setLastMementoID: (state, mementoID) => {
            state.UIState.apps.mementosData.lastMementoID = mementoID
        }
    },
    actions: {
        setEditMode: async ({ state, commit, dispatch }, { editMode }) => {
            state.UIState.apps.editMode = editMode;
            const storeName = editMode ? "appsStage" : "apps"
            await dispatch("appState/bindStore", { storeName: storeName });
            if (!editMode) commit("setCurrentEdit", {})
        }
    },
    getters: {
        currentEditing: (state) => {
            const apps = state.UIState.apps;
            return apps.currentEdit.component && apps.editMode ? true : false
        },
    },
})