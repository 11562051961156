<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <EditButton
        v-if="currentTabID"
        currentEditComponent="ItemListEdit"
        :currentEditAppID="appID"
        :currentEditTabID="currentTabID"
        slot="fixed"
        style="margin: 0"
      ></EditButton>
      <component
        :is="
          ['ItemListEdit', 'ItemInfoEdit'].includes(currentEdit.component) &&
          currentEdit.tabID == currentTabID
            ? ItemListEditView
            : ItemListView
        "
      ></component>
    </ion-content>
  </ion-page>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { IonPage, IonContent } from "@ionic/vue";
import EditButton from "@/views/components/buttons/EditButton.vue";
import ItemListView from "@/views/apps/main-content/ItemListView.vue";
import ItemListEditView from "@/views/apps/main-content/ItemListEditView.vue";

export default {
  name: "AppMainView",
  components: {
    IonPage,
    IonContent,
    EditButton,
  },
  setup() {
    const { appID, currentTabID, currentEdit } = useStoreGetters();

    return {
      appID,
      currentTabID,
      currentEdit,
      ItemListView,
      ItemListEditView,
    };
  },
};
</script>

<style>
</style>