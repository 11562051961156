<template>
  <ion-popover :is-open="isOpen" @didDismiss="cancelPublish" style="margin-top: -150px">
    <ion-content>
      <div class="ion-margin">
        <ion-grid>
          <ion-row>
            <ion-col class="ion-text-center">
              <ion-icon id="alertIcon" :icon="alertCircleOutline"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <div style="font-size: 1.2em; padding: 8px">Vil du udgive ændringerne?</div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-button @click="publish" color="primary" expand="block">Udgiv</ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button @click="cancelPublish" color="primary" expand="block">Fortryd</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
        <ion-progress-bar
          v-if="isLoading"
          type="indeterminate"
          style="position: absolute; bottom: 0; left: 0"
        ></ion-progress-bar>
        <ion-label v-if="publishErrorMessage" color="danger">{{ publishErrorMessage }}</ion-label>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script>
import { ref } from "vue";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { alertCircleOutline } from "ionicons/icons";
import {
  IonButton,
  IonPopover,
  IonContent,
  IonLabel,
  IonCol,
  IonRow,
  IonGrid,
  IonProgressBar,
  IonIcon,
} from "@ionic/vue";

export default {
  name: "PublishStageModal",
  props: {
    isOpen: Boolean,
  },
  components: {
    IonButton,
    IonPopover,
    IonContent,
    IonLabel,
    IonCol,
    IonRow,
    IonGrid,
    IonProgressBar,
    IonIcon,
  },
  model: {
    prop: "isOpen",
    event: "modalClose",
  },
  setup(props, { emit }) {
    const { publishStage } = useStoreSetters();
    const isLoading = ref(false);
    const publishErrorMessage = ref();

    const publish = () => {
      isLoading.value = true;
      publishStage()
        .then(() => {
          emit("modalClose");
          isLoading.value = false;
        })
        .catch((error) => {
          console.log(error);
          publishErrorMessage.value = error.message;
          isLoading.value = false;
        });
    };

    const cancelPublish = () => {
      emit("modalClose");
    };

    return {
      publish,
      cancelPublish,
      publishErrorMessage,
      isLoading,
      alertCircleOutline,
    };
  },
};
</script>

<style scoped>
ion-popover {
  --width: 400px;
}
#alertIcon {
  color: var(--ion-color-primary);
  font-size: 80px;
}
</style>