<template>
  <ion-button @click="closeEdit()" fill="none" class="close-button ion-no-padding">
    <ion-icon slot="icon-only" color="medium" :icon="close"></ion-icon>
  </ion-button>
</template>

<script>
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonButton, IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
export default {
  name: "CloseButton",
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    const { setCurrentEdit } = useStoreSetters();

    const closeEdit = () => {
      setCurrentEdit({})
    };
    return {
      close,
      closeEdit,
    };
  },
};
</script>

<style scoped>
.close-button {
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 10;
  --background: none;
}
</style>