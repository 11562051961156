<template>
  <ion-list
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <ion-list-header>
      <ion-label>
        <h2 :innerHTML="itemList[itemID]?.titel"></h2>
        <p :innerHTML="itemList[itemID]?.subTitel ?? '&zwnj;'"></p>
      </ion-label>
    </ion-list-header>

    <ion-item lines="none">
      <ion-range
        :value="appItemValues?.[itemID] ?? itemList[itemID]?.initialValue"
        :min="itemList[itemID]?.minValue"
        :max="itemList[itemID]?.maxValue"
        @ionChange="inputChanged(toNumber($event.target.value))"
        :pin="itemList[itemID]?.showValue"
      >
        <ion-label slot="start"
          ><h3>{{ itemList[itemID]?.minValue }}</h3></ion-label
        >
        <ion-label slot="end"
          ><h3>{{ itemList[itemID]?.maxValue }}</h3></ion-label
        >
      </ion-range>
    </ion-item>
  </ion-list>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { toNumber } from "@/utilities/utilityFunctions"; 
import {
  IonLabel,
  IonListHeader,
  IonList,
  IonItem,
  IonRange,
} from "@ionic/vue";

export default {
  name: "SliderInputMainEdit",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    IonLabel,
    IonListHeader,
    IonList,
    IonItem,
    IonRange,
  },
  setup(props) {
    const { itemValues, itemList } = useStoreGetters();
    const { setItemValue } = useStoreSetters();

    const appItemValues = computed(() => itemValues.value[props.appID]);

    const inputChanged = (value) => {
      setItemValue(props.appID, props.itemID, value);
    };

    return {
      toNumber,
      itemList,
      appItemValues,
      inputChanged,
    };
  },
};
</script>

<style>
</style>