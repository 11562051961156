<template>
  <div>
    <ion-button
      :id="
        'rule-chain-operator-select-popover-id-' + visibleRuleIndex
      "
      color="medium"
      fill="outline"
      size="small"
      expand="block"
      class="rule-chain-operator-button"
      style="margin-top: -16px; background-color: white; margin-right: -10px;"
      >{{ chainOperatorDisplay }}</ion-button
    >
    <ion-popover
      :trigger="
        'rule-chain-operator-select-popover-id-' + visibleRuleIndex
      "
      :dismiss-on-select="true"
    >
      <ion-content>
        <ion-list>
          <ion-item button @click="updateRuleChainOperator('&&')">OG</ion-item>
          <ion-item button @click="updateRuleChainOperator('||')"
            >ELLER</ion-item
          >
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import _ from "lodash";
import {
  IonButton,
  IonPopover,
  IonContent,
  IonList,
  IonItem,
} from "@ionic/vue";

export default {
  name: "RuleChainOperator",
  props: {
    visibleRuleIndex: {
      type: Number,
    },
    visibleRulesArrayPath: {
      type: Array,
    }
  },
  components: {
    IonButton,
    IonPopover,
    IonContent,
    IonList,
    IonItem,
  },
  setup(props) {
    const { appID, getAppProperty } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const visibleRules = computed(() =>
      getAppProperty.value(props.visibleRulesArrayPath)
    );

    const chainOperator = computed(
      () => visibleRules.value[props.visibleRuleIndex].chainOperator
    );
    
    const chainOperatorDisplay = computed(() => {
      switch (chainOperator.value) {
        case "&&":
          return "OG";
        case "||":
          return "ELLER";
        default:
          return null;
      }
    });
    const updateRuleChainOperator = (operator) => {
      const updateObject = {};
      const refURL = _.join(
        [
          ...props.visibleRulesArrayPath,
          props.visibleRuleIndex,
          "chainOperator",
        ],
        "/"
      );
      updateObject[refURL] = operator;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      chainOperatorDisplay,
      updateRuleChainOperator,
    };
  },
};
</script>

<style scoped>
</style>