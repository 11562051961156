<template>
  <ion-page>
    <ion-content>
      <ion-segment value="welcome" mode="md">
        <ion-segment-button value="welcome">
          <ion-label>Velkommen</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";

export default {
  name: "AppHeaderWelcomeView",
  components: {
    IonPage,
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
};
</script>

<style>
</style>