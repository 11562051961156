<template>
  <editor
    v-model="editorContent"
    api-key="bnfs566jnnj81aaxlhc7dhyss18af89u23ka86cu6yt8w61v"
    :init="editorConfig"
  />
</template>

<script>
import { computed } from "vue";
import Editor from "@tinymce/tinymce-vue";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { ulid } from "ulid";

export default {
  name: "WYSIWYG",
  props: {
    editorType: {
      type: String,
      default: "Full",
    },
    content: {
      type: String,
    },
    appID: {
      type: String,
    },
    subItemID: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  emits: ["update:content"],
  components: {
    editor: Editor,
  },
  setup(props, { emit }) {
    const { uploadImage } = useStoreSetters();
    const { appInfo, tabList, itemList } = useStoreGetters();


/*   const updateContent = (value) => {
    emit("update:content", value);
  } */

    const editorContent = computed({
      // If content is undefined set to empty string so the placeholder is shown
      get: () => props.content ?? "",
      set: (value) => {
        emit("update:content", value);
      },
    });

    /* see */
    /* https://stackoverflow.com/questions/60890137/how-to-implement-tinymce-in-vue-in-a-way-eslint-doesnt-throw-tinymce-is-not-de*/
    const filePickerCallback = (cb) => {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      input.onchange = function () {
        var file = this.files[0];

        var reader = new FileReader();
        reader.onload = function () {
          var imageID = ulid();
          var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(",")[1];
          var blobInfo = blobCache.create(imageID, file, base64);
          blobCache.add(blobInfo);

          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    };

    const imagesUploadHandler = async (blobInfo, success, failure) => {
      /* Prevent running uploadImage when loading editor as imagesUploadHandler is called with blobid<number>*/
      if (blobInfo.id().slice(0, 4) == "blob") return;

      const uploadRequest = await uploadImage(
        blobInfo,
        props.appID ?? "root",
        props.subItemID
      );

      switch (uploadRequest.response) {
        case "SUCCESS":
          success(uploadRequest.value);
          break;
        case "ERROR":
          failure(uploadRequest.value);
          break;
        default:
          break;
      }
    };

    /* Editor configurations */
    /* Must be declared last ie after callsbacks */
    const editorBaseConfig = {
      deprecation_warnings: false,
      language: "da",
      language_url: "/assets/tinymce/da.js",
      placeholder: props.placeholder,
      paste_as_text: true,
      block_formats:
        "Normal=p; Titel=h1; Overskrift 1=h2; Overskrift 2=h3; Overskrift 3=h4",
      elementpath: false,
      file_picker_callback: filePickerCallback,
      images_upload_handler: imagesUploadHandler,
    };

    const inputItemArray = appInfo.value[props.appID]?.appTabs.flatMap(
      (currentTabID) => {
        var tabObject = {
          tabID: currentTabID,
          tabName: tabList.value[currentTabID].name,
        };
        tabObject["items"] = tabList.value[currentTabID].tabItems?.flatMap(
          (currentItemID) => {
            if (itemList.value[currentItemID].type == "input")
              return {
                itemID: currentItemID,
                itemName: itemList.value[currentItemID].name,
              };
            else return [];
          }
        );
        return tabObject.items ? tabObject : [];
      }
    );

    const editorFullConfig = {
      height: 500,
      external_plugins: { insertvariable: "/assets/tinymce/plugin.min.js" },
      inputitems: inputItemArray,
      file_picker_types: "image",
      image_title: true,
      menubar: "edit insert view format table",
      table_border_styles: [
        { title: "Solid", value: "solid" },
        { title: "Dotted", value: "dotted" },
        { title: "Dashed", value: "dashed" },
        { title: "None", value: "none" },
      ],
      menu: {
        edit: {
          title: "Edit",
          items: "undo redo | cut copy paste | searchreplace | code",
        },
        insert: {
          title: "Insert",
          items:
            "insertvariable | image link media inserttable | charmap hr | anchor ",
        },
        view: {
          title: "View",
          items: "code | visualaid visualchars visualblocks ",
        },
        format: {
          title: "Format",
          items:
            "bold italic underline strikethrough superscript subscript | fontsizes lineheight align indent | forecolor backcolor | removeformat",
        },
        table: {
          title: "Table",
          items: "inserttable | cell row column | tableprops deletetable",
        },
      },
      plugins: [
        "advlist autolink lists link image charmap anchor",
        "searchreplace visualblocks code",
        "media table paste code visualchars hr",
      ],
      toolbar:
        "undo redo | formatselect | bold italic underline| \
           alignleft aligncenter | numlist bullist | \
            outdent indent | image | link | charmap ",
    };

    const editorCodeConfig = {
      height: 300,
      external_plugins: { insertvariable: "/assets/tinymce/plugin.min.js" },
      inputitems: inputItemArray,
      nonbreaking_force_tab: true,
      forced_root_block: false,
      // newline_behavior: "linebreak", should be used when forced_root_block is depricated
      menubar: "edit insert",
      menu: {
        edit: {
          title: "Edit",
          items: "searchreplace | code",
        },
        insert: {
          title: "Insert",
          items: "insertvariable",
        },
      },
      plugins: ["searchreplace code nonbreaking", "paste code visualchars"],
      toolbar: false,
    };

    const editorInlineConfig = {
      inline: true,
      external_plugins: { insertvariable: "/assets/tinymce/plugin.min.js" },
      inputitems: inputItemArray,
      menubar: false,
      plugins: ["charmap"],
      toolbar:
        "bold italic underline superscript subscript | numlist bullist | alignleft aligncenter | charmap | insertvariable",
    };

    var editorConfig;
    switch (props.editorType) {
      case "Full":
        editorConfig = { ...editorBaseConfig, ...editorFullConfig };
        break;
      case "Code":
        editorConfig = { ...editorBaseConfig, ...editorCodeConfig };
        break;
      case "Root":
        editorConfig = { ...editorBaseConfig, ...editorFullConfig };
        delete editorConfig.external_plugins;
        delete editorConfig.inputitems;
        break;
      case "Inline":
        editorConfig = { ...editorBaseConfig, ...editorInlineConfig };
        break;
      default:
        editorConfig = { ...editorBaseConfig, ...editorFullConfig };
        break;
    }

    return {
//      updateContent,
      editorContent,
      editorConfig,
      filePickerCallback,
      imagesUploadHandler,
    };
  },
};
</script>