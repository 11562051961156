import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/compat/auth";
import "firebase/compat/functions";

const firebaseConfig = {
  databaseURL: "https://dns-app-mbj-default-rtdb.europe-west1.firebasedatabase.app",
  apiKey: "AIzaSyA65GGR-vLncZT3FzAQNwRX0ksDlgo1F8Q",
  authDomain: "dns-app-mbj.firebaseapp.com",
  projectId: "dns-app-mbj",
  storageBucket: "dns-app-mbj.appspot.com",
  messagingSenderId: "801953566399",
  appId: "1:801953566399:web:ac1dc6dd2def8a0ce6400a",
  measurementId: "G-X275R4FP2H"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firebaseDB = firebase.database();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth();
export const firebaseFunctions = firebase.app().functions('europe-west1');