<template>
  <div class="box-shadow-margin">
    <component
      v-for="itemID in visibleTabItems"
      :key="itemID"
      :is="itemList[itemID]?.templateComponent || 'EmptyModule'"
      :appID="appID"
      :itemID="itemID"
      class="box-shadow-margin"
    ></component>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import RadioSelectList from "@/views/apps/main-content/components/input/RadioSelectList.vue";
import CheckboxSelectList from "@/views/apps/main-content/components/input/CheckboxSelectList.vue";
import NumberInput from "@/views/apps/main-content/components/input/NumberInput.vue";
import SliderInput from "@/views/apps/main-content/components/input/SliderInput.vue";
import DropDownOutput from "@/views/apps/main-content/components/output/DropDownOutput.vue";
import HTMLOutput from "@/views/apps/main-content/components/output/HTMLOutput.vue";
import InfoBoxOutput from "@/views/apps/main-content/components/output/InfoBoxOutput.vue"
import WYSIWYGOutput from "@/views/apps/main-content/components/output/WYSIWYGOutput.vue";
import ChartOutput from "@/views/apps/main-content/components/output/ChartOutput.vue";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
import { useEvaluate } from "@/utilities/composables/useEvaluate";


export default {
  name: "ItemListView",
  components: {
    RadioSelectList,
    CheckboxSelectList,
    NumberInput,
    SliderInput,
    DropDownOutput,
    HTMLOutput,
    InfoBoxOutput,
    WYSIWYGOutput,
    ChartOutput,
    EmptyModule,
  },
  setup() {
    const { appID, currentTabID, tabList, itemList, itemValues } =
      useStoreGetters();
    const { setItemValue } = useStoreSetters();
    const { evaluate } = useEvaluate();

    const tabItems = computed(
      () => tabList.value?.[currentTabID?.value]?.tabItems
    );

    const visibleTabItems = computed(() =>
      tabItems.value?.filter((itemID) =>
        evaluate(itemList.value[itemID]?.visibleRules)
      )
    );

    /* Sets itemValues to null if not visible */
    watch(
      () => itemValues,
      () => {
        tabItems.value
          ?.filter((itemID) => !visibleTabItems.value?.includes(itemID))
          .forEach((itemID) => setItemValue(appID.value, itemID, null));
      },
      { deep: true }
    );

    return {
      appID,
      itemList,
      visibleTabItems,
    };
  },
};
</script>

<style scoped>
</style>