<template>
  <div>
    <div class="box-shadow-margin">
      <ion-accordion-group
        :value="accordionValue"
        @ionChange="setAccordionValue($event.target.value)"
      >
        <ion-accordion
          v-for="container in appList"
          :key="container.apps"
          :value="container.containerID"
        >
          <ion-item slot="header">
            <ion-label>{{ container.title }}</ion-label>
          </ion-item>
          <ion-list slot="content">
            <ion-item
              class="box-shadow-margin"
              v-for="appID in container.apps"
              :key="appID"
              button
              @click="appChanged(appID)"
            >
              <ion-label class="ion-text-wrap">
                <h2>{{ appInfo[appID].name }}</h2>
                <p>{{ appInfo[appID].description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { useRouter } from "vue-router";
import {
  IonItem,
  IonList,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/vue";

export default {
  name: "AppListView",
  components: {
    IonItem,
    IonList,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
  },
  setup() {
    const { appList, appInfo, accordionValue, currentEdit } = useStoreGetters();
    const { setCurrentTabID, setAccordionValue, setCurrentEdit } =
      useStoreSetters();
    const router = useRouter();

    const appChanged = async (appID) => {
      //console.log("before");
      setCurrentTabID(null);
      await router.push({
        name: "AppSinglePage",
        params: { appSlug: appInfo.value[appID].slug },
      });
      if (currentEdit.value.component) setCurrentEdit({});
      // Start with first tab
      setCurrentTabID(appInfo.value[appID].appTabs?.[0]);
    };

    return {
      appList,
      appInfo,
      appChanged,
      accordionValue,
      setAccordionValue,
    };
  },
};
</script>

<style>
</style>