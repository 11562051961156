<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">App rækkefølge</ion-label>
      </ion-list-header>

      <ion-reorder-group
        :disabled="false"
        @ionItemReorder="reorderList($event)"
      >
        <ion-item
          mode="ios"
          v-for="(appListElement, index) in appListFlat"
          :key="index"
          :lines="appListElement.title ? 'none' : undefined"
        >
          <DeleteButtonInline
            v-if="appListElement.containerID"
            @click="deletecontainer(index)"
            slot="start"
          ></DeleteButtonInline>
          <ion-input
            v-if="appListElement.containerID"
            @ionInput="updateInputValue($event, index)"
            mode="ios"
            class="title-input"
            :value="appListElement.title"
            placeholder="Indtast overskrift"
          ></ion-input>
          <ion-label v-if="appListElement.appID" class="ion-margin-start">{{
            appInfo[appListElement.appID].name
          }}</ion-label>
          <ion-reorder
            mode="md"
            slot="end"
          ></ion-reorder>
        </ion-item>
      </ion-reorder-group>
    </ion-list>
  </ion-card>
  <ion-button
    @click="newAppContainer"
    color="primary"
    expand="full"
    fill="solid"
    class="ion-margin"
    >Tilføj</ion-button
  >
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { computed } from "vue";
import { flattenAppList, unFlattenAppList } from "@/utilities/utilityFunctions";
import { ulid } from "ulid";

import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonCard,
  IonReorder,
  IonReorderGroup,
  IonInput,
  IonButton,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";

export default {
  name: "AppListEdit",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonCard,
    IonReorder,
    IonReorderGroup,
    CloseButton,
    IonInput,
    IonButton,
    DeleteButtonInline,
  },
  setup() {
    const { appInfo, appList } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const appListFlat = computed(() => flattenAppList(appList.value));

    const reorderList = (event) => {
      const updateObject = {};
      updateObject["appList"] = unFlattenAppList(
        event.detail.complete(appListFlat.value)
      );
      updateAppProperty(updateObject, "root");
    };

    const updateInputValue = (event, index) => {
      const value = event.target.value;
      const newAppListFlat = appListFlat.value;
      newAppListFlat[index].title = value;
      const updateObject = {};
      updateObject[`appList`] = unFlattenAppList(newAppListFlat);
      updateAppProperty(updateObject, "root");
    };

    const newAppContainer = () => {
      const newAppList = appList.value;
      newAppList.push({ containerID: ulid() });
      const updateObject = {};
      updateObject[`appList`] = newAppList;
      updateAppProperty(updateObject, "root");
    };

    const deletecontainer = (removeIndex) => {
      const newAppList = unFlattenAppList(
        appListFlat.value.filter(
          (appListElement, index) => index != removeIndex
        )
      );
      const updateObject = {};
      updateObject[`appList`] = newAppList;
      updateAppProperty(updateObject, "root");
    };

    return {
      appListFlat,
      appInfo,
      reorderList,
      updateInputValue,
      newAppContainer,
      deletecontainer,
    };
  },
};
</script>

<style scoped>
.title-input {
  font-size: 0.9em;
  font-weight: bold;
}
</style>