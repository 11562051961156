<template>
  <ion-accordion-group :value="itemHasName ? 'module-options' : null">
    <ion-accordion value="module-options">
      <ion-item slot="header" lines="full">
        <ion-label class="ion-text-nowrap"><h1>Numerisk</h1></ion-label>
      </ion-item>

      <ion-list
        slot="content"
        mode="ios"
        style="padding-left: 16px; padding-right: 16px"
      >
        <!-- Titel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Titel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event, 'titel')"
            mode="ios"
            :value="itemList[itemID].titel"
          ></ion-input>
        </ion-item>

        <!-- Subtitel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Undertitel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event, 'subTitel')"
            mode="ios"
            :value="itemList[itemID].subTitel"
          ></ion-input>
        </ion-item>

        <!-- Placeholder -->
        <ion-item mode="ios">
          <ion-label position="stacked">Placeholder</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event, 'placeholder')"
            mode="ios"
            :value="itemList[itemID].placeholder"
          ></ion-input>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonAccordionGroup,
  IonAccordion,
} from "@ionic/vue";

export default {
  name: "NumberInputSidebarEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonAccordionGroup,
    IonAccordion,
  },
  setup() {
    const { appID, itemList, currentEdit } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const itemID = computed(() => currentEdit.value.itemID);

    const itemHasName = itemList.value[itemID.value].name ? true : false;

    const updateItemProperty = (event, property) => {
      const value = event.target.value;
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    return {
      itemHasName,
      itemID,
      itemList,
      updateItemProperty,
    };
  },
};
</script>

<style>
</style>