// Evaluate expression
// Possible operators
// Value operator: ==, >, <= etc,
// Option operator: "===", "SingleSelect", MultipleSelect, MultipleSelectAny, MultipleSelectExact
// Any operator: "notNull"
// Value secondOperand: <subItemID>
// Option secondOperator: <subItemID>

import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { parse, eval as evaluateParseTree } from "expression-eval";
import { isNumber } from "@/utilities/utilityFunctions";
import { forEach, isNil, includes } from "lodash";

export const useEvaluate = () => {
  const evaluate = (rulesArray) => {
    const { appID, itemValues, ruleList, subItemList } = useStoreGetters();
    const appItemValues = computed(() => itemValues.value[appID.value]);

    var expression = "";
    forEach(rulesArray, (rule, ruleIndex) => {
      forEach(ruleList.value[rule.ruleID].subRules, (subRule, subRuleIndex) => {
        if (ruleIndex != 0 && subRuleIndex == 0)
          expression = expression + " " + rule.chainOperator;
        if (subRuleIndex == 0) expression = expression + " (";
        if (subRuleIndex != 0)
          expression = expression + " " + subRule.chainOperator;

        var inputItemValue = appItemValues?.value?.[subRule?.firstOperand];
        if (subRule.operator == "isNil") {
          if (!inputItemValue || inputItemValue.length == 0) expression = expression + " true ";
          else expression = expression + " false ";
        } else if (!inputItemValue) {
          expression = expression + " false ";
        } else if (subRule.operator == "===") {
          expression =
            expression +
            " " +
            "'" +
            inputItemValue +
            "'" +
            " == " +
            "'" +
            subRule.secondOperand +
            "'";
        } else if (subRule.operator == "notNil") {
          expression = expression + " " + !isNil(inputItemValue) + " ";
        } else if (subRule.operator == "includes") {
          const includes = subRule.secondOperand.every((element) => {
            return inputItemValue.includes(element);
          });
          expression = expression + " " + includes + " ";
        } else if (subRule.operator == "exactMatch") {
          const exactMatch =
            subRule.secondOperand.length == inputItemValue.length &&
            subRule.secondOperand.every((element) => {
              return inputItemValue.includes(element);
            });
          expression = expression + " " + exactMatch + " ";
        } else if (subRule.operator == "checked") {
          expression =
            expression +
            " " +
            +inputItemValue.length +
            " == " +
            +subRule.secondOperand;
        } else if(includes(["==", "!=", ">", "<", ">=", "<="], subRule.operator)) {
          if (isNumber(inputItemValue)) {
            expression =
              expression +
              " " +
              inputItemValue +
              " " +
              subRule.operator +
              " " +
              +subRule.secondOperand;
          } else {
            expression =
              expression +
              " " +
              +subItemList.value[inputItemValue]?.subItemValue +
              " " +
              subRule.operator +
              " " +
              +subRule.secondOperand;
          }
        } else {
          expression = expression + false
        }
        if (subRuleIndex == ruleList.value[rule.ruleID].subRules.length - 1)
          expression = expression + " )";
      });
    });
    if (!expression) {
      return true;
    } else {
      return evaluateParseTree(parse(expression));
    }
  };
  return {
    evaluate,
  };
};