<template>
    <div id="icon-picker-container">
        <div v-for="icon in iconList" :key="icon.name" class="icon-picker-element">
            <ion-button @click="selectIcon(icon.name)" size="small" :fill="iconName == icon.name ? 'solid' : 'outline'"
                mode="ios">
                <ion-icon slot="icon-only" :icon="icon.image"></ion-icon>
            </ion-button>
        </div>
    </div>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue"
import {
    bodyOutline, medkitOutline, bandageOutline, personOutline, homeOutline, speedometerOutline,
    statsChartOutline, thermometerOutline, timeOutline, manOutline, womanOutline, waterOutline, alertOutline
} from "ionicons/icons";
export default {
    name: "IconPicker",
    props: { iconName: { type: String } },
    emits: ["iconNameChange"],
    components: { IonButton, IonIcon },
    setup(props, { emit }) {
        const iconList = [
            { name: "bodyOutline", image: bodyOutline },
            { name: "bandageOutline", image: bandageOutline },
            { name: "personOutline", image: personOutline },
            { name: "medkitOutline", image: medkitOutline },
            { name: "homeOutline", image: homeOutline },
            { name: "speedometerOutline", image: speedometerOutline },
            { name: "statsChartOutline", image: statsChartOutline },
            { name: "thermometerOutline", image: thermometerOutline },
            { name: "timeOutline", image: timeOutline },
            { name: "manOutline", image: manOutline },
            { name: "womanOutline", image: womanOutline },
            { name: "waterOutline", image: waterOutline },
            { name: "alertOutline", image: alertOutline },
        ]

        const selectIcon = (iconName) => {
            // Deselect icon if already chosen
            if (iconName == props.iconName) emit("iconNameChange", null)
            else emit("iconNameChange", iconName)
        }
        return {
            iconList,
            selectIcon,
        }
    }
}
</script>
<style scoped>
#icon-picker-container {
    display: flex;
    flex-wrap: wrap;
}
</style>