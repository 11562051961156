<template>
  <ion-page>
    <ion-content>
      <EditButton
        currentEditComponent="TabListEdit"
        :currentEditAppID="appID"
        slot="fixed"
        style="margin: 0"
      ></EditButton>
      <component
        :is="
          ['TabListEdit', 'TabInfoEdit'].includes(currentEdit.component)
            ? TabListEditView
            : TabListView
        "
      ></component>
    </ion-content>
  </ion-page>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { IonPage, IonContent } from "@ionic/vue";
import EditButton from "@/views/components/buttons/EditButton.vue";
import TabListView from "@/views/apps/header/TabListView.vue";
import TabListEditView from "@/views/apps/header/TabListEditView.vue";

export default {
  name: "AppHeaderView",
  components: {
    IonPage,
    IonContent,
    EditButton,
  },
  setup() {
    const { appID, currentEdit } = useStoreGetters();

    return {
      appID,
      currentEdit,
      TabListView,
      TabListEditView,
    };
  },
};
</script>

<style>
</style>