<template>
  <ion-content><component :is="currentEdit.component"></component></ion-content>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { IonContent } from "@ionic/vue";
import AppInfoEdit from "@/views/apps/right-sidebar/components/AppInfoEdit.vue";
import AppListEdit from "@/views/apps/right-sidebar/components/AppListEdit.vue";
import TabListEdit from "@/views/apps/right-sidebar/components/TabListEdit.vue";
import TabInfoEdit from "@/views/apps/right-sidebar/components/TabInfoEdit.vue";
import ItemListEdit from "@/views/apps/right-sidebar/components/ItemListEdit.vue";
import ItemInfoEdit from "@/views/apps/right-sidebar/components/ItemInfoEdit.vue";
import MainWelcomeEdit from "@/views/apps/right-sidebar/components/static/MainWelcomeEdit.vue";

export default {
  name: "AppRightSidebarView",
  components: {
    IonContent,
    AppInfoEdit,
    AppListEdit,
    TabListEdit,
    TabInfoEdit,
    ItemListEdit,
    ItemInfoEdit,
    MainWelcomeEdit,
  },
  setup() {
    const { currentEdit } = useStoreGetters();

    return {
      currentEdit,
    };
  },
};
</script>

<style>
</style>