<template>
  <ion-app style="flex-direction: row">
    <ion-grid style="display: flex; flex-direction: column">
      <!-- Header -->
      <ion-row v-if="true" style="height: 60px; flex-wrap: nowrap">
        <!-- Left Header -->
        <ion-col
          v-if="showAppList"
          size-md="4"
          size="12"
          style="transition: max-width 800ms ease"
          :class="{ 'shrink-first-column': currentEditing }"
        >
          <ion-item lines="none">
            <LogInModal :isOpen="logInModalOpen" @modalClose="() => (logInModalOpen = false)"></LogInModal>
            <ion-button v-if="!userLoggedIn" @click="showLoginModal" fill="none"
              ><ion-icon slot="icon-only" :icon="logInOutline"></ion-icon
            ></ion-button>
            <ion-button v-if="userLoggedIn" @click="signUserOut" fill="none"
              ><ion-icon slot="icon-only" :icon="logOutOutline"></ion-icon
            ></ion-button>

            <ion-toggle
              v-if="userLoggedIn"
              @ionChange="setEditModeFunction($event)"
              :checked="editMode"
              slot="start"
            ></ion-toggle>
            <PublishStageModal :isOpen="publishStageModalOpen" @modalClose="() => (publishStageModalOpen = false)"></PublishStageModal>
            <ion-button v-if="editMode" @click="showPublishStageModal" color="primary">Udgiv</ion-button>
            <ion-button v-if="editMode" :disabled="disableUndo" @click="undoChange(currentMementoID)" fill="none"
              ><ion-icon slot="icon-only" :icon="arrowUndoOutline"></ion-icon
            ></ion-button>
            <ion-button v-if="editMode && !disableRedo" @click="redoChange(currentMementoID)" fill="none"
              ><ion-icon slot="icon-only" :icon="arrowRedoOutline"></ion-icon
            ></ion-button>
          </ion-item>
        </ion-col>
        <!-- Main Header -->
        <ion-col
          :size-md="showAppList ? 8 : 12"
          size="12"
          style="transition: max-width 800ms ease"
          :class="{ 'shrink-second-column': currentEditing }"
        >
          <router-view name="header" :key="route.path"> </router-view>
        </ion-col>
        <ion-col
          v-if="currentEditing"
          size-md="3"
          size="12"
          style="transform: translate(100%, 0) ease"
          :class="{ 'slide-third-column': currentEditing }"
        >
        </ion-col>
      </ion-row>

      <ion-row style="flex: 1; flex-wrap: nowrap">
        <!-- Left Sidebar -->
        <ion-col
          v-if="showAppList"
          size-md="4"
          size="12"
          style="min-height: 200px; transition: max-width 800ms ease"
          :class="{ 'shrink-first-column': currentEditing }"
        >
          <router-view name="leftSidebar" :key="route.path"> </router-view>
        </ion-col>

        <!-- Main content -->
        <ion-col
          :size-md="showAppList ? 8 : 12"
          size="12"
          style="min-height: 200px; transition: max-width 800ms ease"
          :class="{ 'shrink-second-column': currentEditing }"
        >
          <router-view :key="route.path"></router-view>
        </ion-col>

        <!-- Right Sidebar -->
        <transition name="slide-sidebar">
          <ion-col
            v-if="currentEditing"
            size-md="3"
            size="12"
            style="transform: translate(100%, 0) ease"
            :class="{ 'slide-third-column': currentEditing }"
          >
            <router-view name="rightSidebar" :key="route.path"> </router-view>
          </ion-col>
        </transition>
      </ion-row>
    </ion-grid>
  </ion-app>
</template>

<script>
import { ref, computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { useRoute, RouterView } from "vue-router";
import { firebaseAuth } from "@/store/firebase";
import LogInModal from "@/views/apps/header/LogInModal.vue";
import PublishStageModal from "@/views/apps/header/PublishStageModal.vue";
import { logInOutline, logOutOutline, arrowUndoOutline, arrowRedoOutline } from "ionicons/icons";
import { IonApp, IonItem, IonGrid, IonRow, IonCol, IonToggle, IonButton, IonIcon } from "@ionic/vue";

export default {
  name: "App",
  components: {
    IonApp,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonToggle,
    RouterView,
    IonButton,
    IonIcon,
    LogInModal,
    PublishStageModal
},
  setup() {
    document.title = "DNS web-app";

    const { appID, editMode, currentEditing, currentEdit, mementos } = useStoreGetters();
    const { setEditMode, undoChange, redoChange } = useStoreSetters();
    const route = useRoute();

    const showAppList = route.query.showAppList == "false" ? false : true;

    const currentMementoID = computed(() =>
      !appID || currentEdit.value.component == "AppInfoEdit" ? "root" : appID.value
    );

    const disableUndo = computed(() => (mementos.value?.[currentMementoID.value]?.undo?.length ? false : true));
    const disableRedo = computed(() => (mementos.value?.[currentMementoID.value]?.redo?.length ? false : true));

    const setEditModeFunction = (event) => {
      setEditMode(event.target.checked);
    };

    const userLoggedIn = ref(false);
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        userLoggedIn.value = true;
      } else {
        userLoggedIn.value = false;
      }
    });

    const logInModalOpen = ref(false);
    const showLoginModal = () => {
      logInModalOpen.value = true;
    };

    const publishStageModalOpen = ref(false);
    const showPublishStageModal = () => {
      publishStageModalOpen.value = true;
    };

    const signUserOut = () => {
      firebaseAuth
        .signOut()
        .then(() => console.log("Signed out"))
        .catch((error) => {
          console.log("Error: " + error);
        });
    };

    return {
      route,
      editMode,
      setEditModeFunction,
      currentEditing,
      showPublishStageModal,
      firebaseAuth,
      userLoggedIn,
      logInModalOpen,
      publishStageModalOpen,
      signUserOut,
      logInOutline,
      logOutOutline,
      arrowUndoOutline,
      arrowRedoOutline,
      showLoginModal,
      showAppList,
      mementos,
      undoChange,
      redoChange,
      currentMementoID,
      disableUndo,
      disableRedo,
    };
  },
};
</script>
<style scoped>
.shrink-first-column {
  max-width: 25% !important;
}

.shrink-second-column {
  max-width: 50% !important;
}

.slide-third-column {
  transform: translate(0, 0) !important;
}

.slide-sidebar-enter-active,
.slide-sidebar-leave-active {
  transition: all 800ms ease;
}

.slide-sidebar-enter-to,
.slide-sidebar-leave {
  transform: translate(0, 0);
}
</style>