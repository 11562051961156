/**
 * parseHTML
 * Replaces all <span data-item-id="itemValueID"/> with values the user has inputed in the corrosponding Item of type "Input"
 * 
 * evaluateExpressions
 * Evaluates all expressions wrapped in double moustaches
 */

import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
//import { parse, eval as evaluateParseTree } from 'expression-eval';
import { isNumber } from "@/utilities/utilityFunctions";
import { forEach, isArray } from "lodash";

export const useExpression = () => {
  const parseHTML = (HTMLString) => {
    // If empty string do not pass
    if (HTMLString == null) return "";

    const { appID, itemValues, subItemList } = useStoreGetters();
    const appItemValues = computed(() => itemValues.value[appID.value]);

    // Loop through the values of all the items of the app"
    forEach(appItemValues.value, (itemValue, itemValueID) => {
      // Get all span elements in HTMLString that has a data-item-id attribute
      const regex = new RegExp('<span +[^>]*?data-item-id="' + itemValueID + ".*?>.*?</span>", "g");
      // Delete span if there is no value for the item
      if (itemValue == null) {
        HTMLString = HTMLString.replaceAll(regex, "");
      }
      // If the value of the item is a number replace the span with the number
      else if (isNumber(itemValue)) {
        HTMLString = HTMLString.replaceAll(regex, itemValue);
        // If the value og the item is a string (of subItemIDs) replace the span with readable list of subItem names
      } else if (isArray(itemValue)) {
        const arayAsString = itemValue
          .map((subItemID) => subItemList.value[subItemID].name)
          .join(", ");
        HTMLString = HTMLString.replaceAll(regex, arayAsString);
        // If the value is a string (a subItemID) replace the span with the subItem value
      } else {
        HTMLString = HTMLString.replaceAll(regex, String(subItemList.value?.[itemValue].subItemValue));
      }
    });
    // Removes all span's with data-item-id attributes ie the ones that has not been assined a value in previous forEach
    const regex = new RegExp("<span +[^>]*?data-item-id=.*?</span>", "g");
    HTMLString.replaceAll(regex,"") 
    return HTMLString;
  };
  const evaluateExpressions = (HTMLString) => {
    // prev regex {{(.*)}}
    var newHTMLString = HTMLString.replaceAll(/{{([^{}]*)}}/g, (matchedExpression, p1) => {
      try {
        /*Removes eventual HTML tags*/
        return eval(p1.replaceAll(/(<([^>]+)>)/g, ""));
      } catch (error) {
        return "";
      }
    });
    return newHTMLString;
  };
  return {
    parseHTML,
    evaluateExpressions,
  };
};
