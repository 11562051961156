<template>
  <div style="display: flex; flex-wrap: nowrap; align-items: center; margin-right: 14px">
    <ion-segment
      style="flex-grow: 1"
      class="box-shadow-margin"
      scrollable
      :value="currentTabID ?? appTabs?.[0]"
      @ionChange="tabChanged($event)"
      mode="md"
    >
      <ion-segment-button
        v-for="tabID in appTabs"
        :key="tabID"
        :value="tabID"
        :disabled="!evaluate(tabList[tabID].visibleRules)"
      >
        <ion-label>{{ tabList[tabID].name }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
export default {
  name: "TabListView",
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
  setup() {
    const { appID, appInfo, tabList, currentTabID, currentEdit } = useStoreGetters();
    const { setCurrentTabID, setCurrentEdit } = useStoreSetters();
    const { evaluate } = useEvaluate();

    const appTabs = computed(() => appInfo.value?.[appID.value]?.appTabs);

    const tabChanged = (event) => {
      if (currentEdit.value.component) setCurrentEdit({});
      const currentTabID = event.target.value;
      setCurrentTabID(currentTabID);
    };

    return {
      appID,
      appTabs,
      tabList,
      currentTabID,
      tabChanged,
      evaluate,
    };
  },
};
</script>

<style scoped>
ion-segment-button {
  --color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.8);
}
</style>