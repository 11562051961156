import { createRouter, createWebHistory } from "@ionic/vue-router";
import AppMainWelcomeView from "@/views/apps/main-content/AppMainWelcomeView.vue";
import AppMainView from "@/views/apps/main-content/AppMainView.vue";
import AppHeaderView from "@/views/apps/header/AppHeaderView";
import AppHeaderWelcomeView from "@/views/apps/header/AppHeaderWelcomeView";
import AppLeftSidebarView from "@/views/apps/left-sidebar/AppLeftSidebarView";
import AppRightSidebarView from "@/views/apps/right-sidebar/AppRightSidebarView";

const routes = [
  {
    path: '/',
    redirect: '/apps'
  },
  {
    path: '/apps',
    name: 'AppMainPage',
    components: {
      default: AppMainWelcomeView,
      header: AppHeaderWelcomeView,
      leftSidebar: AppLeftSidebarView,
      rightSidebar: AppRightSidebarView,
    }
  },
  {
    path: '/apps/:appSlug',
    name: 'AppSinglePage',
    components: {
      default: AppMainView,
      header: AppHeaderView,
      leftSidebar: AppLeftSidebarView,
      rightSidebar: AppRightSidebarView,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
