<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <!-- ItemListEdit -->
    <EmptyModule
      v-if="currentEdit.component == 'ItemListEdit'"
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit but other item selected -->
    <EmptyModule
      v-else-if="
        currentEdit.component == 'ItemInfoEdit' && currentEdit.itemID != itemID
      "
      :moduleText="itemList[itemID]?.name"
    ></EmptyModule>

    <!-- ItemInfoEdit -->
    <div v-else-if="currentEdit.subItemID != null">
      <ion-accordion-group value="tester">
        <ion-accordion value="tester">
          <!-- Header -->
          <ion-item
            slot="header"
            style="border-radius: 8px"
            :style="{
              '--background':
                subItemList[currentEdit.subItemID]?.titleBackgroundColor ??
                '#000000',
              '--accordion-chevron':
                subItemList[currentEdit.subItemID]?.titleColor ?? '#FFFFFF',
            }"
          >
            <ion-label
              style="font-size: 1.2em"
              :style="{
                color:
                  subItemList[currentEdit.subItemID]?.titleColor ?? '#FFFFFF',
              }"
              >{{ subItemList[currentEdit.subItemID]?.title }}</ion-label
            >
          </ion-item>

          <!-- Copy text button -->
          <ion-button
            v-if="subItemList[currentEdit.subItemID]?.showCopyButton"
            slot="content"
            class="ion-no-padding ion-no-margin"
            style="position: absolute; right: 14px; z-index: 10"
            fill="none"
            ><ion-icon slot="icon-only" :icon="copyOutline"></ion-icon
          ></ion-button>

          <!-- Content -->
          <div slot="content" class="ion-padding">
            <WYSIWYG
              :key="currentEdit.subItemID"
              :appID="currentEdit.appID"
              :subItemID="currentEdit.subItemID"
              :content="subItemList[currentEdit.subItemID]?.output"
              @update:content="updateSubItemProperty($event)"
              editorType="Full"
              placeholder="Skriv tekst her..."
            ></WYSIWYG>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </div>

    <!-- SubItem not selected -->
    <div v-else>
      <EmptyModule moduleText="Vælg/tilføj output"></EmptyModule>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
import WYSIWYG from "@/views/components/WYSIWYG.vue";
import { copyOutline } from "ionicons/icons";
import {
  IonItem,
  IonLabel,
  IonAccordionGroup,
  IonAccordion,
  IonButton,
  IonIcon,
} from "@ionic/vue";

export default {
  name: "DropDownOutputMainEdit",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    EmptyModule,
    WYSIWYG,
    IonItem,
    IonLabel,
    IonAccordionGroup,
    IonAccordion,
    IonButton,
    IonIcon,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const updateSubItemProperty = (content) => {
      const updateObject = {};
      updateObject[`subItemList/${currentEdit.value.subItemID}/output`] =
        content;
      updateAppProperty(updateObject, props.appID);
    };

    return {
      itemList,
      subItems,
      subItemList,
      updateSubItemProperty,
      editMode,
      currentEdit,
      copyOutline,
    };
  },
};
</script>

<style>
ion-icon.ion-accordion-toggle-icon {
  color: var(--accordion-chevron);
}
</style>