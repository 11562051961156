<template>
  <transition name="fade-in">
    <ion-button fill="none" class="edit-button ion-no-margin">
      <ion-icon slot="icon-only" :icon="isExpanded ? chevronUp: chevronDown"></ion-icon>
    </ion-button>
  </transition>
</template>

<script>
import { IonButton, IonIcon } from "@ionic/vue";
import { chevronDown, chevronUp } from "ionicons/icons";
export default {
  name: "ExpandButton",
  props: {
      isExpanded: Boolean,
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      chevronDown,
      chevronUp,
    };
  },
};
</script>

<style scoped>
.edit-button {
  position: absolute;
  bottom: -6px;
  right: 50%;
  z-index: 10;
  --padding-start: 2px;
  --padding-end: 2px;
  --padding-bottom: 2px;
  --padding-top: 2px;
  --background: none;
  width: 24px;
  height: 24px;
}

.edit-button::part(native) {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>