export const appStatePlugin = (store) => {
  // called when the store is initialized

  // initialize Firestore
  store.dispatch("appState/bindStore", {storeName: "apps"});

  var prevInputIDs;
  store.subscribe((mutation, state) => {

    // Set new itemValues in localDB if changed
    if (mutation.type == "vuexfire/SET_VALUE") {
      console.log("Firestore updated")
      var newInputIDs = Object.keys(state.appState.apps.itemList ?? {}).sort();
      if (prevInputIDs != JSON.stringify(newInputIDs)) {
        store.commit("appState/updateItemValueIDs")
        prevInputIDs = JSON.stringify(newInputIDs);
      }
    }
  })
}