<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">App information</ion-label>
      </ion-list-header>

      <ion-item mode="ios">
        <ion-label position="stacked">Navn</ion-label>
        <ion-input
          @ionInput="updateInputValue($event)"
          name="name"
          mode="ios"
          clear-input
          :value="appInfo[appID]?.name"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Beskrivelse</ion-label>
        <ion-input
          @ionInput="updateInputValue($event)"
          name="description"
          mode="ios"
          clear-input
          :value="appInfo[appID]?.description"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Web-adresse</ion-label>
        <ion-input
          @ionInput="updateInputValue($event)"
          name="slug"
          mode="ios"
          placeholder="mit-app-navn"
          clear-input
          :value="appInfo[appID]?.slug"
        ></ion-input>
      </ion-item>
    </ion-list>

    <ion-button
      @click="deleteCurrentApp(appID)"
      color="danger"
      expand="full"
      fill="solid"
      class="ion-margin"
      >Slet</ion-button
    >
  </ion-card>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { useRouter } from "vue-router";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonListHeader,
  IonButton,
  IonCard,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";

export default {
  name: "AppInfoEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonListHeader,
    IonButton,
    IonCard,
    CloseButton,
  },
  setup() {
    const { currentEdit, appInfo } = useStoreGetters();
    const { updateAppProperty, deleteApp } = useStoreSetters();
    const router = useRouter();

    const appID = computed(() => currentEdit.value.appID);

    const updateInputValue = (event) => {
      const property = event.target.name;
      const value = event.target.value;
      const updateObject = {};
      updateObject[`appInfo/${appID.value}/${property}`] = value;
      updateAppProperty(updateObject, "root");
    };

    const deleteCurrentApp = async (appID) =>  {
      await deleteApp(appID)
      router.replace({ path: '/' })
    }

    return {
      appID,
      appInfo,
      updateInputValue,
      deleteCurrentApp,
    };
  },
};
</script>

<style>
</style>