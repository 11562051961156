<template>
  <div
    style="display: inline-block; vertical-align: top"
    :style="{ width: itemList[itemID]?.width ?? '100%' }"
  >
    <div
      v-for="(subItemID) in subItems"
      :key="subItemID"
    >
      <div
        v-if="evaluate(subItemList[subItemID]?.visibleRules)"
        style="position: relative"
      >
        <!-- Copy text button -->
        <ion-button
          v-if="subItemList[subItemID]?.showCopyButton"
          slot="content"
          @click="copyToClipboard($event)"
          class="ion-no-padding ion-no-margin"
          style="position: absolute; right: 14px"
          fill="none"
          ><ion-icon slot="icon-only" :icon="copyOutline"></ion-icon
        ></ion-button>

        <!-- Content -->
        <div
          v-html="
            evaluateExpressions(parseHTML(subItemList[subItemID]?.output))
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useEvaluate } from "@/utilities/composables/useEvaluate";
import { useExpression } from "@/utilities/composables/useExpression";
import { copyOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/vue";
export default {
  name: "HTMLOutput",
  props: {
    appID: String,
    itemID: String,
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup(props) {
    const { editMode, currentEdit, itemList, subItemList } = useStoreGetters();
    const { evaluate } = useEvaluate();
    const { parseHTML, evaluateExpressions } = useExpression();

    const subItems = computed(() => itemList.value[props.itemID].subItems);

    const copyToClipboard = (event) => {
      navigator.clipboard.writeText(event.target.nextElementSibling.textContent);
    };

    return {
      itemList,
      subItems,
      subItemList,
      editMode,
      currentEdit,
      copyOutline,
      copyToClipboard,
      evaluate,
      parseHTML,
      evaluateExpressions,
    };
  },
};
</script>

<style>
</style>