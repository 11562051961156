var justResizedFromNull = false;

export default {
  id: "responsive",
  install: function() {
  },
  beforeUpdate: function (chart, args) {
    if (args.mode == "attach") {
      justResizedFromNull = true;
    }
  },
  beforeDraw: function (chart) {
    if (justResizedFromNull) {
      chart.reset();
      chart.update();
      justResizedFromNull = false;
    }
  },
};
