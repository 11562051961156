<template>
  <Line
    :chart-data="chartData"
    :chart-options="chartOptions"
    :chart-id="chartID"
    :plugins="plugins"
    ref="LineScatterChartRef"
  />
</template>

<script>
import { watchEffect, ref, computed } from "vue";
import { useExpression } from "@/utilities/composables/useExpression";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import responsive from "@/utilities/chartjs/responsive";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  ScatterController,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
} from "chart.js";
import { uniqueId, merge } from "lodash";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  ScatterController,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
  responsive
);
export default {
  name: "LineScatterChart",
  components: { Line },
  props: {
    subItemID: {
      type: String,
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { subItemList } = useStoreGetters();
    const { parseHTML, evaluateExpressions } = useExpression();

    const LineScatterChartRef = ref();
    const chartID = uniqueId("line-scatter-chart-");
    const chartData = ref({ datasets: [{ label: "", data: [] }] });
    const chartOptionsInput = ref({});
    const backgroundColors = ref([]);

    /* chartData */
    watchEffect(
      () => {
        try {
          chartData.value = JSON.parse(
            evaluateExpressions(
              parseHTML(subItemList.value[props.subItemID]?.chartData)
            ).replaceAll(/<[^>]+>|&nbsp;/g, "")
          );
        } catch (error) {
          console.log("error chartData");
          chartData.value = { datasets: [{ label: "", data: [] }] };
        }

        if (backgroundColors.value?.length == chartData.value.datasets.length) {
          backgroundColors.value?.forEach((backgroundColor, index) => {
            chartData.value.datasets[index].backgroundColor = backgroundColor;
          });
        }
      },
      { flush: "post" }
    );

    /* Options */
    // Default values
    const defaultChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      transitions: { attach: { animation: { duration: 0 } } },
      scales: {
        x: { grid: { display: false } },
        y: { grid: { display: false } },
      },
      plugins: {
        tooltip: {
          enabled: false,
        },
      },
    };

    // Input values
    watchEffect(() => {
      try {
        chartOptionsInput.value = JSON.parse(
          evaluateExpressions(
            parseHTML(subItemList.value[props.subItemID]?.options)
          ).replaceAll(/<[^>]+>|&nbsp;/g, "")
        );
      } catch (error) {
        console.log("error options");
        chartOptionsInput.value = {}
      }
    });

    const chartOptions = computed(() => merge(defaultChartOptions, chartOptionsInput.value))

    /* backgroundColors */
    watchEffect(() => {
      var backgroundColorsInput;
      try {
        backgroundColorsInput = JSON.parse(
          evaluateExpressions(
            parseHTML(subItemList.value[props.subItemID]?.backgroundColors)
          ).replaceAll(/<[^>]+>|&nbsp;/g, "")
        );
      } catch (error) {
        console.log("error backgroundColor");
        backgroundColorsInput = [];
      }

      if (backgroundColorsInput.length == 0) backgroundColors.value = [];
      backgroundColors.value = backgroundColorsInput.map((backgroundColor) => {
        if (backgroundColor.type == "Solid") return backgroundColor.color ?? [];
        else if (backgroundColor.type == "LinearGradient") {
          var linearGradient =
            LineScatterChartRef.value?.chart.ctx.createLinearGradient(
              ...backgroundColor.gradientValues
            );
          backgroundColor.colorStopValues.forEach((colorStop, index) => {
            linearGradient?.addColorStop(
              colorStop,
              backgroundColor.colorStopColors[index]
            );
          });
          return linearGradient ?? [];
        } else return [];
      });
    });

    return {
      chartData,
      chartOptions,
      chartID,
      LineScatterChartRef,
    };
  },
};
</script>

<style>
</style>