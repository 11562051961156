<template>
  <ion-popover
    :is-open="isOpen"
    @didDismiss="cancelLogIn"
    style="margin-top: -150px"
  >
    <ion-content>
      <CloseButton @click="cancelLogIn"></CloseButton>
      <div style="" class="ion-padding ion-margin">
        <h1 style="margin-top: 5px">Brugerlogin</h1>
        <ion-item>
          <ion-label position="stacked">Email</ion-label>
          <ion-input
            v-model="userEmail"
            @keyup.enter="logIn"
            type="email"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Kodeord</ion-label>
          <ion-input
            v-model="userPassword"
            @keyup.enter="logIn"
            type="password"
          ></ion-input>
        </ion-item>
        <ion-button
          @click="logIn"
          color="primary"
          expand="block"
          style="margin-top: 16px"
          >Log på</ion-button
        >
        <ion-progress-bar
          v-if="isLoading"
          type="indeterminate"
          style="position: absolute; bottom: 0; left: 0"
        ></ion-progress-bar>
        <ion-label v-if="logInErrorMessage" color="danger">{{
          logInErrorMessage
        }}</ion-label>
      </div>
    </ion-content>
  </ion-popover>
</template>

<script>
import { ref } from "vue";
import { firebaseAuth } from "@/store/firebase";
import CloseButton from "@/views/components/buttons/CloseButton.vue";
import {
  IonItem,
  IonButton,
  IonPopover,
  IonContent,
  IonInput,
  IonLabel,
  IonProgressBar,
} from "@ionic/vue";

export default {
  name: "LogInModel",
  props: {
    isOpen: Boolean,
  },
  components: {
    IonItem,
    IonButton,
    IonPopover,
    IonContent,
    IonInput,
    IonLabel,
    CloseButton,
    IonProgressBar,
  },
  model: {
    prop: "isOpen",
    event: "modalClose",
  },
  setup(props, { emit }) {
    const userEmail = ref("");
    const userPassword = ref("");
    const logInErrorMessage = ref("");
    const isLoading = ref(false);

    const logIn = () => {
      isLoading.value = true;
      firebaseAuth
        .signInWithEmailAndPassword(userEmail.value, userPassword.value)
        .then((user) => {
          console.log("Logged in:");
          console.log(user);
          emit("modalClose");
          isLoading.value = false;
        })
        .catch((error) => {
          console.log(error);
          logInErrorMessage.value = error.message;
          isLoading.value = false;
        });
    };

    const cancelLogIn = () => {
      emit("modalClose");
    };

    return {
      userEmail,
      userPassword,
      logIn,
      cancelLogIn,
      logInErrorMessage,
      isLoading,
    };
  },
};
</script>

<style scoped>
</style>