<template>
  <ion-accordion-group :value="itemHasName ? 'module-options' : null">
    <ion-accordion value="module-options">
      <ion-item slot="header" lines="full">
        <ion-label class="ion-text-nowrap"><h1>Tjekliste</h1></ion-label>
      </ion-item>

      <ion-list slot="content" mode="ios" style="padding-left: 16px; padding-right: 16px">
        <!-- Titel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Titel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event.target.value, 'titel')"
            name="titel"
            mode="ios"
            :value="itemList[itemID].titel"
          ></ion-input>
        </ion-item>

        <!-- Subtitel input -->
        <ion-item mode="ios">
          <ion-label position="stacked">Undertitel</ion-label>
          <ion-input
            @ionInput="updateItemProperty($event.target.value, 'subTitel')"
            name="subTitel"
            mode="ios"
            :value="itemList[itemID].subTitel"
          ></ion-input>
        </ion-item>

        <!-- Show group checkbox -->
        <ion-item mode="ios">
          <ion-label position="fixed">Vis gruppe-tjekboks</ion-label>
          <ion-toggle
            @ionChange="updateItemProperty($event.target.checked, 'showGroupCheckbox')"
            :checked="itemList[itemID].showGroupCheckbox"
            :key="itemList[itemID].showGroupCheckbox"
            mode="md"
          >
          </ion-toggle>
        </ion-item>

        <!-- SubItem input -->
        <ion-label position="stacked">Valgmuligheder</ion-label>
        <ion-reorder-group :disabled="false" @ionItemReorder="reorderList($event)">
          <div v-for="subItemID in itemList[itemID].subItems" :key="subItemID">
            <!-- Name edit -->
            <ion-item mode="ios">
              <DeleteButtonInline @click="deleteSubItem(subItemID, itemID, appID)" slot="start"></DeleteButtonInline>

              <ion-input
                title="Navn"
                @ionInput="updateSubItemProperty($event.target.value, subItemID, 'name')"
                name="name"
                mode="ios"
                placeholder="Tekst..."
                :value="subItemList[subItemID]?.name"
              ></ion-input>
              <ion-reorder mode="md" slot="end"></ion-reorder>
            </ion-item>
          </div>
        </ion-reorder-group>
        <ion-item mode="ios" lines="none">
          <AddButtonInline @click="addSubItem()" slot="start"></AddButtonInline>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonItem,
  IonLabel,
  IonInput,
  IonList,
  IonReorder,
  IonReorderGroup,
  IonAccordionGroup,
  IonAccordion,
  IonToggle,
} from "@ionic/vue";
import AddButtonInline from "@/views/components/buttons/AddButtonInline.vue";
import DeleteButtonInline from "@/views/components/buttons/DeleteButtonInline.vue";

export default {
  name: "CheckboxSelectListSidebarEdit",
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonReorder,
    IonReorderGroup,
    IonAccordionGroup,
    IonAccordion,
    AddButtonInline,
    DeleteButtonInline,
    IonToggle,
  },
  setup() {
    const { itemList, currentEdit, subItemList } = useStoreGetters();
    const { updateAppProperty, newSubItem, deleteSubItem } = useStoreSetters();

    const appID = computed(() => currentEdit.value.appID);
    const tabID = computed(() => currentEdit.value.tabID);
    const itemID = computed(() => currentEdit.value.itemID);

    const itemHasName = itemList.value[itemID.value].name ? true : false;

    const updateItemProperty = (value, property) => {
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    const updateSubItemProperty = (value, subItemID, property) => {
      const updateObject = {};
      updateObject[`subItemList/${subItemID}/${property}`] = value;
      updateAppProperty(updateObject, appID.value);
    };

    const addSubItem = () => {
      newSubItem(appID.value, tabID.value, itemID.value, {
        name: "",
      });
    };

    const reorderList = (event) => {
      const updateObject = {};
      updateObject[`itemList/${itemID.value}/subItems`] = event.detail.complete(itemList.value[itemID.value].subItems);
      updateAppProperty(updateObject, appID.value);
    };

    return {
      appID,
      itemHasName,
      itemID,
      itemList,
      subItemList,
      updateItemProperty,
      reorderList,
      addSubItem,
      deleteSubItem,
      updateSubItemProperty,
    };
  },
};
</script>

<style>
</style>