<template>
  <transition name="fade-in">
    <ion-button
      v-if="editMode"
      fill="outline"
      @mouseover="mouseEvent('onMouseOver', $event)"
      @mouseout="mouseEvent('onMouseOut', $event)"
      @click.stop="mouseEvent('onMouseClick', $event)"
      class="edit-button ion-no-margin"
    >
      <ion-icon slot="icon-only" :icon="pencil"></ion-icon>
    </ion-button>
  </transition>
</template>

<script>
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonButton, IonIcon } from "@ionic/vue";
import { pencil } from "ionicons/icons";
export default {
  name: "EditButton",
  props: {
    currentEditComponent: { type: String, default: null },
    currentEditAppID: { type: String, default: null },
    currentEditTabID: { type: String, default: null },
    currentEditItemID: { type: String, default: null },
    currentEditsubItemID: { type: String, default: null },
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup(props) {
    const { editMode } = useStoreGetters();
    const { setCurrentEdit } = useStoreSetters();

    const mouseEvent = (prop, event) => {
      if (prop == "onMouseOver") {
        // clostest param should be a list of elements with hover effect eg 'ion-item, ion-button'
        event.target
          .closest("ion-item, ion-content")
          .style.setProperty("--background-hover", "none");
      } else if (prop == "onMouseOut") {
        event.target
          .closest("ion-item, ion-content")
          .style.setProperty("--background-hover", "");
      } else if (prop == "onMouseClick") {
        setCurrentEdit({
          component: props.currentEditComponent,
          appID: props.currentEditAppID,
          tabID: props.currentEditTabID,
          itemID: props.currentEditItemID,
          subItemID: props.currentEditsubItemID,
        });
      }
    };

    return {
      pencil,
      mouseEvent,
      editMode,
    };
  },
};
</script>

<style scoped>
.edit-button {
  top: 0px;
  right: 0px;
  z-index: 10;
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-bottom: 4px;
  --padding-top: 4px;
  --background: white;
  width: 32px;
  height: 32px;
  margin-right: -12px;
  margin-top: -12px;
}

.edit-button::part(native) {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

/* Prevents hover effect on parent in the right upper corner*/
.edit-button::after {
  content: "";
  width: 16px;
  height: 16px;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.5s ease;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>