<template>
  <ion-card>
    <CloseButton></CloseButton>
    <ion-list mode="ios" class="ion-padding">
      <ion-list-header lines="full">
        <ion-label class="ion-text-nowrap">Fane rækkefølge</ion-label>
      </ion-list-header>

      <ion-reorder-group
        :disabled="false"
        @ionItemReorder="reorderList($event)"
      >
        <ion-item mode="ios" v-for="appTabID in appTabs" :key="appTabID">
          {{ tabList[appTabID].name }}
          <ion-reorder mode="md" slot="end"></ion-reorder>
        </ion-item>
      </ion-reorder-group>
    </ion-list>
  </ion-card>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonCard,
  IonReorder,
  IonReorderGroup,
} from "@ionic/vue";
import CloseButton from "@/views/components/buttons/CloseButton.vue";

export default {
  name: "TabListEdit",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonCard,
    IonReorder,
    IonReorderGroup,
    CloseButton,
  },
  setup() {
    const { appInfo, tabList, currentEdit } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const appID = computed(() => currentEdit.value.appID);
    const appTabs = computed(() => appInfo.value[appID.value].appTabs);

    const reorderList = (event) => {
      const updateObject = {};
      updateObject[`appInfo/${appID.value}/appTabs`] = event.detail.complete(appTabs.value);
      updateAppProperty(updateObject, appID.value);
    };

    return {
      appTabs,
      tabList,
      reorderList,
    };
  },
};
</script>

<style>
</style>