<template>
  <div>
    <div
      class="box-shadow-margin"
      :class="{
        'box-shadow':
          currentEdit.component == 'ItemListEdit' &&
          currentEdit.tabID == currentTabID,
      }"
    >
      <component
        v-for="itemID in tabItems"
        :key="itemID"
        :is="itemList[itemID]?.templateComponent ? itemList[itemID]?.templateComponent + 'MainEdit' : 'EmptyModule'"
        :appID="appID"
        :itemID="itemID"
        @click="setCurrentEditFunction(itemID)"
        class="box-shadow-margin"
        :class="{
          'box-shadow':
            currentEdit.component == 'ItemInfoEdit' &&
            currentEdit.itemID == itemID,
          'box-shadow-hover':
            (currentEdit.component == 'ItemInfoEdit' ||
              currentEdit.component == 'ItemListEdit') &&
            currentEdit.itemID != itemID &&
            currentEdit.tabID == currentTabID,
        }"
      ></component>
    </div>
    <ion-button
      @click="newItemFunction"
      color="primary"
      expand="full"
      fill="solid"
      class="ion-margin"
      >Tilføj</ion-button
    >
  </div>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonButton } from "@ionic/vue";
import RadioSelectListMainEdit from "@/views/apps/main-content/components/input/RadioSelectListMainEdit.vue";
import CheckboxSelectListMainEdit from "@/views/apps/main-content/components/input/CheckboxSelectListMainEdit.vue";
import NumberInputMainEdit from "@/views/apps/main-content/components/input/NumberInputMainEdit.vue";
import SliderInputMainEdit from "@/views/apps/main-content/components/input/SliderInputMainEdit.vue";
import DropDownOutputMainEdit from "@/views/apps/main-content/components/output/DropDownOutputMainEdit.vue";
import HTMLOutputMainEdit from "@/views/apps/main-content/components/output/HTMLOutputMainEdit.vue";
import InfoBoxOutputMainEdit from "@/views/apps/main-content/components/output/InfoBoxOutputMainEdit.vue";
import WYSIWYGOutputMainEdit from "@/views/apps/main-content/components/output/WYSIWYGOutputMainEdit.vue";
import ChartOutputMainEdit from "@/views/apps/main-content/components/output/ChartOutputMainEdit.vue";
import EmptyModule from "@/views/apps/main-content/components/EmptyModule.vue";
export default {
  name: "ItemListEditView",
  components: {
    IonButton,
    RadioSelectListMainEdit,
    CheckboxSelectListMainEdit,
    NumberInputMainEdit,
    SliderInputMainEdit,
    DropDownOutputMainEdit,
    HTMLOutputMainEdit,
    InfoBoxOutputMainEdit,
    WYSIWYGOutputMainEdit,
    ChartOutputMainEdit,
    EmptyModule,
  },
  setup() {
    const { appID, currentTabID, currentEdit, tabList, itemList } =
      useStoreGetters();
    const { setCurrentEdit, newItem } = useStoreSetters();

    const tabItems = computed(
      () => tabList.value[currentTabID.value]?.tabItems
    );

    const setCurrentEditFunction = (itemID) => {
      // Only change to infoEdit if editing current tab and it is not the current item edited
      if (
        currentEdit.value.tabID == currentTabID.value &&
        currentEdit.value.itemID != itemID
      ) {
        setCurrentEdit({
          component: "ItemInfoEdit",
          appID: appID.value,
          tabID: currentTabID.value,
          itemID: itemID,
        });
      }
    };

    const newItemFunction = () => {
      newItem(appID.value, currentTabID.value);
    };

    return {
      appID,
      itemList,
      currentEdit,
      currentTabID,
      tabItems,
      setCurrentEditFunction,
      newItemFunction,
    };
  },
};
</script>

<style scoped>
</style>