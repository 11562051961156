<template>
  <div class="empty-module">
    <div>
      <h1>{{ moduleText }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyModule",
  props: {
    moduleText: {
      type: String,
      default: "Modul",
    }
  },
  setup() {
    return {};
  },
};
</script>

<style>
.empty-module {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-medium);
  background-color: var(--ion-color-light);
}
</style>