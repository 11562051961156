<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <EditButton
        currentEditComponent="MainWelcomeEdit"
        :currentEditAppID="null"
        :currentEditTabID="null"
        slot="fixed"
        style="margin: 0"
      ></EditButton>
      <div
        class="box-shadow-margin"
        :class="{ 'box-shadow': currentEdit.component == 'MainWelcomeEdit' }"
      >
        <div
          v-if="!(currentEdit.component == 'MainWelcomeEdit')"
          :innerHTML="welcomeMessageContent"
        ></div>
      <WYSIWYG
      v-if="currentEdit.component == 'MainWelcomeEdit'"
        :content="welcomeMessageContent"
        @update:content="updateInputValue($event)"
        editorType="Root"
        placeholder="Skriv tekst her..."
      ></WYSIWYG>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { computed } from "vue";
import { useStoreGetters } from "@/utilities/composables/useStoreGetters";
import { useStoreSetters } from "@/utilities/composables/useStoreSetters";
import { IonPage, IonContent } from "@ionic/vue";
import EditButton from "@/views/components/buttons/EditButton.vue";
import WYSIWYG from "@/views/components/WYSIWYG.vue";

export default {
  name: "AppMainWelcomeView",
  components: {
    IonPage,
    IonContent,
    EditButton,
    WYSIWYG,
  },
  setup() {
    const { currentEdit, root, editMode } = useStoreGetters();
    const { updateAppProperty } = useStoreSetters();

    const welcomeMessageContent = computed(
      () => root.value?.welcomeMessageContent
    );

    const updateInputValue = (content) => {
      const updateObject = {};
      updateObject[`root/welcomeMessageContent`] = content;
      updateAppProperty(updateObject, "root");
    };

    return {
      editMode,
      currentEdit,
      welcomeMessageContent,
      updateInputValue,
    };
  },
};
</script>

<style scoped>
</style>